// import React from 'react';
// import { Checkbox } from "@mui/material";
// import PropTypes from "prop-types";

// const JumboCheckbox = ({ checked, onChange, sx }) => {
//     const [isChecked, setIsChecked] = React.useState(checked);

//     React.useEffect(() => {
//         onChange(isChecked);
//     }, [isChecked, onChange]);

//     const handleChange = (event) => {
//         setIsChecked(event.target.checked);
//     };

//     return (
//         <Checkbox
//             checked={isChecked}
//             onChange={handleChange}
//             sx={sx}
//         />
//     );
// };

// JumboCheckbox.defaultProps = {
//     onChange: () => {}
// };

// JumboCheckbox.propTypes = {
//     checked: PropTypes.bool,
//     onChange: PropTypes.func,
//     sx: PropTypes.object
// };

// export default JumboCheckbox;

import React from "react";
import { Checkbox } from "@mui/material";
import PropTypes from "prop-types";

const JumboCheckbox = ({ checked, onChange, sx }) => {
  const [isChecked, setIsChecked] = React.useState(checked);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    onChange(event); // Pass the event object
  };

  return <Checkbox checked={isChecked} onChange={handleChange} sx={sx} />;
};

JumboCheckbox.defaultProps = {
  onChange: () => {},
};

JumboCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

export default JumboCheckbox;
