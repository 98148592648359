// import { createSlice } from "@reduxjs/toolkit";
// import { useAppDispatch } from "./../store";

// const initialState = {
//   sectionFormData: {
//     groupTitle: "",
//     indexes: [
//       {
//         name: "",
//         label: "",
//         type: "",
//         options: "",
//         required: true,
//       },
//     ],
//   },
// };

// const sectionFormSlice = createSlice({
//   name: "sectionForm",
//   initialState,
//   reducers: {
//     setSectionFormData: (state, action) => {
//       state.sectionFormData = action.payload;
//     },
//   },
// });

// export const { setSectionFormData } = sectionFormSlice.actions;

// export const useSectionFormActions = () => {
//   const dispatch = useAppDispatch();

//   const setFormData = (formData) => {
//     dispatch(setSectionFormData(formData));
//   };

//   return {
//     setFormData,
//   };
// };

// const { reducer } = sectionFormSlice;

// export default reducer;

// sectionData.slice.js
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "./../store";

const initialState = {
  sectionFormData: {
    groupTitle: "",
    indexes: [
      {
        name: "",
        label: "",
        type: "",
        options: "",
        required: true,
      },
    ],
  },
};

const sectionFormSlice = createSlice({
  name: "sectionForm",
  initialState,
  reducers: {
    setSectionFormData: (state, action) => {
      state.sectionFormData = action.payload;
    },
  },
});

export const { setSectionFormData } = sectionFormSlice.actions;

export const useSectionFormActions = () => {
  const dispatch = useAppDispatch();

  const setFormData = (formData) => {
    dispatch(setSectionFormData(formData));
  };

  return {
    setFormData,
  };
};

const { reducer } = sectionFormSlice;

export default reducer;
