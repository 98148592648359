/* eslint-disable no-template-curly-in-string */
import { Typography } from "@mui/material";
import React, { useState, useMemo } from "react";
import Div from "@jumbo/shared/Div";
import LoadingButton from "@mui/lab/LoadingButton";
import OutlinedSecondaryTextField from "./../../../../../@jumbo/components/Textfield/index";
import Span from "./../../../../../@jumbo/shared/Span/Span";
import useSwalWrapper from "./../../../../../@jumbo/vendors/sweetalert2/hooks";
import { useNavigate } from "react-router-dom";
import { Cancel } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Close } from "@mui/icons-material";
import AuthLayout from "app/layouts/auth-layout/AuthLayout";
import { useSignupFormActions } from "./../../../../redux/userData/userData.slice";
import usePasswordForm from "../hooks/usePasswordForm";
import { useSelector } from "react-redux";
import { useAuthApiActions } from "../query/useAuthApiActions";
import { ASSET_LOGOS } from "./../../../../utils/constants/paths";
import { ASSET_IMAGES } from "./../../../../utils/constants/paths";
import { IconButton } from "@mui/material";

const defaultValues = {
  password: "",
  confirmPassword: "",
};

function PasswordForm() {
  const [userData, setUserData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigate = useNavigate();
  // const { location } = window;
  const origin = `${window.location.origin}`;

  console.log("location--->", origin);

  const { setFormData } = useSignupFormActions();

  const storeData = useSelector((state) => state.userData.signupFormData);

  const { trySignup } = useAuthApiActions();

  const onSubmit = async (values) => {
    const response = await trySignup({
      origin,
      password: values.password,
      ...storeData,
    });
    if (response) {
      const { id, verificationCode } = response?.data;
      setUserData(response?.data);
      sweetAlerts(id, verificationCode);
    }
  };

  console.log("res", userData);

  const Swal = useSwalWrapper();
  const sweetAlerts = (userId, code) => {
    const handleNavigation = () => {
      Swal.close();
      navigate(`/`);
      Swal.getPopup().removeEventListener("click", handleNavigation);
    };

    const email = storeData?.email;

    Swal.fire({
      title: `Email instruction sent<p>Please follow the instructions we sent to your inbox.</p><p>${email}</p>`,
      // html: `<p>Please follow the instructions we sent to your inbox.</p><p>${email}</p>`,
      imageUrl: `${ASSET_LOGOS}/email.png`,
      imageWidth: 100,
      imageHeight: 100,
      showCloseButton: true,
      imageAlt: "Custom image",
      focusConfirm: false,
      showConfirmButton: false,
    }).then(() => {
      navigate(`/`);
    });

    Swal.getPopup().addEventListener("click", handleNavigation);
  };

  const formik = usePasswordForm(onSubmit, defaultValues);

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    formik;

  const passwordMatch =
    values.password === values.confirmPassword && values.password !== "";

  return (
    <AuthLayout
      title={" Create a strong password"}
      description={
        " Create a strong password with a mixture of letters, numbers and symbols."
      }
    >
      <Div
        sx={{
          mt: "20px",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          fontSize={{ lg: "30px", md: "20px", xs: "15px" }}
          color="#371B65"
          fontWeight={500}
          mb={3}
          lineHeight={"47px"}
          letterSpacing={"5%"}
          fontFamily={"Roboto"}
        >
          Create Account
        </Typography>
        {/* <Button
          variant="text"
          startIcon={
            <img
              src={`${ASSET_IMAGES}/backbutton.png`}
              alt="Jumbo React"
              style={{ width: "20px", height: "20px" }}
            />
          }
          sx={{
            position: "absolute",
            top: "10px",
            right: "8px",
            zIndex: 1,
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "left",
            color: "#371B65",
          }}
        >
          Back
        </Button> */}
      </Div>
      <Div>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            error={!!touched.password && !!errors.password}
            helperText={
              (touched.password && errors && errors.password) ||
              (values.password &&
              !/(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/.test(
                values.password
              ) ? (
                <ol
                  style={{
                    listStyle: "none",
                    padding: 0,
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    textAlign: "left",
                  }}
                >
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color:
                        values.password.length >= 8 ? "#00AF5B" : "inherit",
                    }}
                  >
                    {values.password.length >= 8 ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    ) : (
                      <Cancel
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    )}
                    At least 8 characters
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: /(?=.*[a-zA-Z])/.test(values.password)
                        ? "#00AF5B"
                        : "inherit",
                    }}
                  >
                    {/(?=.*[a-zA-Z])/.test(values.password) ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    ) : (
                      <Cancel
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    )}
                    1 letter
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: /(?=.*\d)/.test(values.password)
                        ? "#00AF5B"
                        : "inherit",
                    }}
                  >
                    {/(?=.*\d)/.test(values.password) ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    ) : (
                      <Cancel
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    )}
                    1 number
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: /(?=.*[@$!%*?&])/.test(values.password)
                        ? "#00AF5B"
                        : "inherit",
                    }}
                  >
                    {/(?=.*[@$!%*?&])/.test(values.password) ? (
                      <CheckCircleIcon
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    ) : (
                      <Cancel
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      />
                    )}
                    1 special character
                  </li>
                </ol>
              ) : (
                ""
              ))
            }
            label="Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            placeholder={"eg. *******"}
            endAdornment={
              <IconButton onClick={handleTogglePassword} edge="end">
                {showPassword ? (
                  <img
                    src={`${ASSET_IMAGES}/openeye.png`}
                    alt="Jumbo React"
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  <img
                    src={`${ASSET_IMAGES}/closeeye.png`}
                    alt="Jumbo React"
                    style={{ width: "24px", height: "24px" }}
                  />
                )}
              </IconButton>
            }
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            value={values.password}
            onBlur={handleBlur("password")}
            onChange={handleChange("password")}
          />
        </Div>
        <Div
          sx={{ mt: "20px", width: { lg: "433px", md: "100%", xs: "100%" } }}
        >
          <OutlinedSecondaryTextField
            focused
            error={
              (!!touched.confirmPassword && !!errors.confirmPassword) ||
              (!passwordMatch && touched.confirmPassword)
            }
            helperText={
              (touched.confirmPassword && errors && errors.confirmPassword) ||
              (!passwordMatch &&
                touched.confirmPassword &&
                "Passwords do not match")
            }
            label="Confirm Password"
            variant="outlined"
            placeholder={"eg. *******"}
            endAdornment={
              <IconButton onClick={handleToggleConfirmPassword} edge="end">
                {showConfirmPassword ? (
                  <img
                    src={`${ASSET_IMAGES}/openeye.png`}
                    alt="Jumbo React"
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  <img
                    src={`${ASSET_IMAGES}/closeeye.png`}
                    alt="Jumbo React"
                    style={{ width: "24px", height: "24px" }}
                  />
                )}
              </IconButton>
            }
            In
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
            value={values.confirmPassword}
            onBlur={handleBlur("confirmPassword")}
            onChange={handleChange("confirmPassword")}
          />
        </Div>
        <Div
          sx={{
            width: { lg: "392px", md: "100%", xs: "100%" },
            height: "44px",
            mt: "18px",
          }}
        >
          <Typography
            sx={{
              color: "#717171",
              fontFamily: "Roboto",
              fontSize: { lg: "14px", md: "12px", xs: "10px" },
              fontWeight: 400,
              lineHeight: "22px",
              letterSpacing: "0.05em",
              textAlign: "left",
            }}
          >
            By signing up, you agree to Documan’s{" "}
            <Span sx={{ color: "#1A5DBE" }}>Terms of Service</Span> and{" "}
            <Span sx={{ color: "#1A5DBE" }}>Privacy Policy</Span>.
          </Typography>
        </Div>
      </Div>

      <LoadingButton
        fullWidth
        variant="contained"
        size="large"
        sx={{
          mt: "23px",
          padding: "5.43px, 7.24px, 5.43px, 7.24px",
          width: { lg: "433px", md: "100%", xs: "100%" },
          height: "50px",
          backgroundColor: "#371B65",
          fontFamily: "Roboto",
          fontSize: { lg: "14px", md: "12px", xs: "10px" },
          fontWeight: 500,
          lineHeight: "20px",
          letterSpacing: "0.05em",
          textAlign: "left",
          textTransform: "none",
        }}
        onClick={() => handleSubmit()}
      >
        Agree and Sign Up
      </LoadingButton>
    </AuthLayout>
  );
}

export default PasswordForm;
