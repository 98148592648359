// import React, { useMemo } from "react";
// import {
//   FormControl,
//   TextField,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   Checkbox,
//   FormGroup,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormLabel,
// } from "@mui/material";
// import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
// import useDynamicForm from "./../../hooks/useDyanamicForm";
// import Span from "./../../../../../../@jumbo/shared/Span/Span";
// import Div from "./../../../../../../@jumbo/shared/Div/Div";

// const DynamicFormField = ({ index, onChange }) => {
//   const { fieldName, type, options } = index;

//   console.log("Raw options:", options);

//   let parsedOptions = [];
//   try {
//     parsedOptions = JSON.parse(
//       options.replace(/(\w+:)|(\w+ :)/g, function (matched) {
//         return '"' + matched.substring(0, matched.length - 1) + '":';
//       })
//     );
//     console.log("Parsed options:", parsedOptions);
//   } catch (e) {
//     console.error("Failed to parse options:", e);
//   }

//   const menuItems = useMemo(
//     () =>
//       Array.isArray(options)
//         ? options.map((option) => {
//             return { id: option.value, name: option.value };
//           })
//         : [],
//     [options]
//   );

//   console.log("menuItems:", menuItems);

//   const handleChange = (event) => {
//     onChange(fieldName, event.target.value);
//   };

//   const handleCheckboxChange = (event) => {
//     const { checked, value } = event.target;
//     onChange(fieldName, event.target.value);
//   };

//   switch (type) {
//     case "DropDown":
//       return (
//         <JumboFormLabelTextField
//           fullWidth
//           isSelect
//           id={fieldName}
//           name={fieldName}
//           menuItems={menuItems}
//           labelStyle={{
//             fontFamily: "Inter",
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label={fieldName}
//           onSelectHandler={handleChange}
//           placeholder=""
//           type="text"
//         />
//       );
//     case "TextField":
//       return (
//         <JumboFormLabelTextField
//           fullWidth
//           labelStyle={{
//             fontFamily: "Inter",
//             color: "#000000",
//             fontSize: { xs: "1.2rem", md: "16px" },
//             fontWeight: 400,
//           }}
//           label={fieldName}
//           name="fieldName"
//           placeholder="Enter info"
//           type="text"
//           value={fieldName}
//           onChange={handleChange}
//         />
//       );
//     case "RadioButton":
//       return (
//         <Div sx={{ display: "flex", flexDirection: "column", mt: "10px" }}>
//           <FormLabel
//             sx={{
//               color: "inherit",
//               fontSize: {
//                 xs: "12px",
//                 md: "14px",
//                 xl: "15px",
//                 fontWeight: "500",
//               },
//               marginTop: "5px",
//               marginBottom: "4px",
//               position: "relative",
//               zIndex: 9999,
//               textTransform: "capitalize",
//             }}
//           >
//             {fieldName}
//           </FormLabel>
//           <Span>
//             <RadioGroup name={fieldName} row onChange={handleChange}>
//               {parsedOptions.map((option, index) => (
//                 <FormControlLabel
//                   key={index}
//                   value={option.value}
//                   control={<Radio />}
//                   label={option.value}
//                 />
//               ))}
//             </RadioGroup>
//           </Span>
//         </Div>
//       );
//     case "Checkbox":
//       return (
//         <Div sx={{ display: "flex", flexDirection: "column" }}>
//           <FormLabel
//             sx={{
//               color: "inherit",
//               fontSize: {
//                 xs: "12px",
//                 md: "14px",
//                 xl: "15px",
//                 fontWeight: "500",
//               },
//               marginTop: "5px",
//               marginBottom: "4px",
//               position: "relative",
//               zIndex: 9999,
//               textTransform: "capitalize",
//             }}
//           >
//             {fieldName}
//           </FormLabel>
//           <Span>
//             {parsedOptions.map((option, index) => (
//               <FormControlLabel
//                 key={index}
//                 control={<Checkbox name={fieldName} value={option.value} />}
//                 value={option.value}
//                 onChange={handleCheckboxChange}
//               />
//             ))}
//           </Span>
//         </Div>
//       );
//     default:
//       return null;
//   }
// };

// export default DynamicFormField;

import React, { useMemo } from "react";
import {
  FormControlLabel,
  RadioGroup,
  Checkbox,
  FormLabel,
  Radio,
} from "@mui/material";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Span from "./../../../../../../@jumbo/shared/Span/Span";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import { useEffect } from "react";
import { useState } from "react";

const DynamicFormField = ({ index, onChange }) => {
  const { fieldName, type, options } = index;
  const [parsedOptions, setParsedOptions] = useState([]);

  console.log("Original options string:", options);

  // Remove backslashes and double quotes from the options string
  const cleanedOptions = options.replace(/\\/g, "").replace(/\"/g, "");
  console.log("Cleaned options string:", cleanedOptions);
  useEffect(() => {
    try {
      let cleanedOptions = options.replace(/\\/g, "").replace(/\"/g, "");
      cleanedOptions = cleanedOptions
        .replace(/(\w+):/g, '"$1":')
        .replace(/:([^,\]}]+)/g, ':"$1"');

      const parsed = JSON.parse(cleanedOptions);
      if (Array.isArray(parsed)) {
        setParsedOptions(parsed);
      } else {
        throw new Error("Parsed options is not an array");
      }
    } catch (e) {
      console.error("Failed to parse options:", e);
      setParsedOptions([]);
    }
  }, [options]);

  const menuItems = useMemo(
    () =>
      Array.isArray(parsedOptions)
        ? parsedOptions.map((option) => {
            return { id: option.value, name: option.value };
          })
        : [],
    [parsedOptions]
  );

  const handleChange = (event) => {
    onChange(fieldName, event.target.value);
  };

  const handleCheckboxChange = (event) => {
    onChange(fieldName, event.target.value);
  };

  switch (type) {
    case "DropDown":
      return (
        <JumboFormLabelTextField
          fullWidth
          isSelect
          id={fieldName}
          name={fieldName}
          menuItems={menuItems}
          labelStyle={{
            fontFamily: "Inter",
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={fieldName}
          onSelectHandler={handleChange}
          placeholder=""
          type="text"
        />
      );
    case "TextField":
      return (
        <JumboFormLabelTextField
          fullWidth
          labelStyle={{
            fontFamily: "Inter",
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={fieldName}
          name="fieldName"
          placeholder="Enter info"
          type="text"
          value={fieldName}
          onChange={handleChange}
        />
      );
    case "RadioButton":
      return (
        <Div sx={{ display: "flex", flexDirection: "column", mt: "10px" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              zIndex: 9999,
              textTransform: "capitalize",
            }}
          >
            {fieldName}
          </FormLabel>
          <Span>
            <RadioGroup name={fieldName} row onChange={handleChange}>
              {parsedOptions.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.value}
                />
              ))}
            </RadioGroup>
          </Span>
        </Div>
      );
    case "Checkbox":
      return (
        <Div sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              zIndex: 9999,
              textTransform: "capitalize",
            }}
          >
            {fieldName}
          </FormLabel>
          <Span>
            {parsedOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                control={<Checkbox name={fieldName} value={option.value} />}
                value={option.value}
                onChange={handleCheckboxChange}
              />
            ))}
          </Span>
        </Div>
      );
    default:
      return null;
  }
};

export default DynamicFormField;
