import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField as InputField,
  InputProps,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import * as React from "react";

// type IOnSelect = (_: SelectChangeEvent) => void;

function JumboFormLabelTextField(props) {
  const {
    name,
    type,
    value,
    disabled,
    error,
    label,
    onChange,
    onSelectHandler,
    onBlur,
    onFocus,
    placeholder,
    required,
    style,
    helperText,
    icon,
    iconEnd,
    iconStart,
    isSelect,
    menuItems,
    className,
    hasAllValue,
    minDate,
    maxLength,
    rows,
    maxRows,
    multiline,
    darkDisable,
    onClickStartIcon,
    onClickEndIcon,
    labelStyle,
  } = props;

  const inputLabelProps =
    (value && (disabled || darkDisable)) || type === "date"
      ? { shrink: true, position: "top" }
      : {};

  return (
    <FormControl sx={style}>
      {label && (
        <FormLabel
          htmlFor={name}
          sx={{
            color: "inherit",
            fontSize: {
              xs: "12px",
              md: "14px",
              xl: "15px",
              fontWeight: "500",
              ...labelStyle,
            },
            marginTop: "5px",
            marginBottom: "4px",
            position: "relative",
            zIndex: 9999,
            textTransform: "capitalize",
          }}
        >
          {label}
          {required ? <span style={{ color: "red" }}>*</span> : null}
        </FormLabel>
      )}

      {!isSelect ? (
        <InputField
          placeholder={placeholder}
          aria-describedby="my-helper-text"
          className={className}
          color="success"
          disabled={disabled}
          error={error}
          required={required}
          rows={rows}
          maxRows={maxRows}
          multiline={multiline}
          id={name}
          InputLabelProps={inputLabelProps}
          InputProps={{
            sx: {
              backgroundColor: "#fff",
            },
            startAdornment: iconStart ? (
              <InputAdornment
                position="start"
                sx={{ cursor: "pointer", color: "black" }}
                onClick={onClickStartIcon}
              >
                {icon}
              </InputAdornment>
            ) : null,
            endAdornment: iconEnd ? (
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer", color: "black" }}
                onClick={onClickEndIcon}
              >
                {icon}
              </InputAdornment>
            ) : null,
          }}
          name={name}
          sx={{
            // backgroundColor: "#fff",
            fontSize: { xs: "1rem", xl: "1.3rem" },

            "& .MuiInputBase-root": {
              height: multiline ? "auto" : 40,
            },
            width: "100%",
            "&::placeholder": {
              fontFamily: "Inter",
              fontSize: "16px",
              lineHeight: "24px",
            },
            // Remove box-sizing property
          }}
          type={type}
          value={value}
          variant="outlined"
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
      ) : (
        <Select
          displayEmpty
          className={className}
          disabled={disabled}
          error={error}
          size="small"
          inputProps={{ "aria-label": "Without label" }}
          sx={
            {
              // backgroundColor: "#fff",
              // "& .MuiInputBase-input.Mui-disabled": {
              //   WebkitTextFillColor: darkDisable ? "#000000" : "inherit",
              // },
            }
          }
          value={value?.toString()}
          onChange={onSelectHandler}
        >
          {menuItems?.map((item) => {
            // const values = Object.values(item).join(",");
            return (
              <MenuItem key={item.name} value={item.id}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {helperText && (
        <FormHelperText id="my-helper-text" sx={{ color: "red" }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

JumboFormLabelTextField.defaultProps = {
  style: {
    width: "100%",
    marginBottom: "10px",
  },
  helperText: "",
  maxLength: 1000,
};

export default JumboFormLabelTextField;
