import React, { useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import FilesList from "./components/FilesList/FilesList";
import { Grid, ButtonGroup, Typography } from "@mui/material";
import JumboButton from "./../../../../@jumbo/components/JumboButton/JumboButton";
import { getAssetPath } from "./../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../utils/constants/paths";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { useTrashApiAction } from "./query/useTrashApiActions";
import { useJumboDialog } from "./../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { FamilyRestroomRounded } from "@mui/icons-material";
import Div from "./../../../../@jumbo/shared/Div/Div";
import { useFetchTrashFileFolder } from "./query/useFetchTrashFileFolder";

function TrashFiles() {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectId, setSelectId] = useState("");
  const [type, setType] = useState("file");
  const [refreshList, setRefreshList] = useState(FamilyRestroomRounded);
  const { showDialog, hideDialog } = useJumboDialog();
  const { tryRestoreFile, tryPermanentDeleteFile } = useTrashApiAction();
  const [query, setQuery] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  // const type = "file";
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: trashData,
    isLoading: isLoadingTrash,
    refetch: trashRefetch,
  } = useFetchTrashFileFolder(type, page, limit, query, false);

  React.useEffect(() => {
    if (type || trashRefetch) {
      trashRefetch();
    }
  }, [type, trashRefetch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectFolderType = () => {
    setType("folder");
  };

  const handleSelectFileType = () => {
    setType("file");
  };

  const handleDocumentSelection = (selected) => {
    setSelectedUsers(selected);
  };

  const handleSelectedId = (selectId) => {
    setSelectId(selectId);
  };

  const handleDeleteFiles = () => {
    const deletedValues = {
      items: selectedUsers.map((id) => ({
        id: id,
        type: type,
      })),
    };

    showDialog({
      variant: "confirm",
      title: "Are you sure you want to Delete?",
      content: "You won't be able to recover this contact later",
      onYes: async () => {
        try {
          await tryPermanentDeleteFile?.(deletedValues);
          trashRefetch();
        } catch (error) {
          console.error("Error during deletion:", error);
          // Optionally handle the error
        } finally {
          hideDialog();
        }
      },
      onNo: hideDialog,
    });
  };

  //   showDialog({
  //     variant: "confirm",
  //     title: "Are you sure you want to Delete ?",
  //     content: "You won't be able to recover this contact later",
  //     onYes: () => {
  //       tryPermanentDeleteFile?.(deletedValues);
  //       // setRefreshList(true);
  //       trashRefetch();
  //       hideDialog();
  //     },
  //     onNo: hideDialog,
  //   });
  // };

  const handleRestoreFiles = async () => {
    console.log("Selected IDs:", selectedUsers);
    showDialog({
      variant: "confirm",
      title: "Are you sure you want to restore?",
      content: "You won't be able to recover this contact later",
      onYes: async () => {
        try {
          await tryRestoreFile?.({ items: selectedUsers, type: type }); // Await the restore operation
          trashRefetch(); // Refetch after restore is complete
        } catch (error) {
          console.error("Error during restore:", error);
        } finally {
          hideDialog();
        }
      },
      onNo: hideDialog,
    });
  };

  return (
    <Grid container spacing={3.75} sx={{ width: "100%" }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={2} md={2} lg={2}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: { lg: "20px", md: "16px", xs: "14px" },
                fontWeight: "400",
                lineHeight: "20px",
                color: "#000000",
              }}
            >
              Recycle
            </Typography>
          </Grid>
          <Grid item xs={4} md={2} lg={2}>
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <JumboButton
                startIcon={
                  <img
                    src={getAssetPath(`${ASSET_IMAGES}/files.png`, "18x18")}
                    alt="pin"
                    width={18}
                  />
                }
                sx={{
                  width: "91px",
                  height: "32px",
                  padding: "6px 16px 6px 16px",
                  gap: "8px",
                  backgroundColor: "#371B65",
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "0.05em",
                }}
                onClick={handleSelectFileType}
              >
                Files
              </JumboButton>
              <JumboButton
                variant="outlined"
                startIcon={
                  <img
                    src={getAssetPath(`${ASSET_IMAGES}/folder.png`, "18x18")}
                    alt="pin"
                    width={18}
                  />
                }
                sx={{
                  width: "101px",
                  height: "32px",
                  padding: "6px, 16px, 6px, 16px",
                  gap: "8px",
                  color: "#371B65",
                  border: "1px solid #371B65",
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: { lg: "14px", md: "12px", xs: "10px" },
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "0.05em",
                }}
                onClick={handleSelectFolderType}
              >
                Folder
              </JumboButton>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Div>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <JumboButton
              startIcon={<RestoreFromTrashIcon />}
              sx={{
                width: "200px",
                height: "50px",
                padding: "6px 12px 6px 12px",
                gap: "8px",
                backgroundColor: "#371B65",
                textTransform: "none",
                fontFamily: "Roboto",
                fontSize: { lg: "14px", md: "12px", xs: "10px" },
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.05em",
                color: "white",

                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
              onClick={handleRestoreFiles}
            >
              Restore {type}
            </JumboButton>

            <JumboButton
              variant="outlined"
              startIcon={<DeleteOutlineIcon />}
              sx={{
                // width: "101px",
                // height: "32px",
                padding: "6px, 16px, 6px, 16px",
                gap: "8px",
                color: "#371B65",
                border: "1px solid #371B65",
                textTransform: "none",
                fontFamily: "Roboto",
                fontSize: { lg: "14px", md: "12px", xs: "10px" },
                fontWeight: 500,
                lineHeight: "20px",
                letterSpacing: "0.05em",
              }}
              onClick={handleDeleteFiles}
            >
              Delete {type}
            </JumboButton>
          </ButtonGroup>
        </Div>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <FilesList
          selectedUsers={selectedUsers}
          onDocumentSelect={handleDocumentSelection}
          type={type}
          refreshList={refreshList}
          handleSelectedId={handleSelectedId}
          trashData={trashData}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  );
}

export default TrashFiles;
