import React, { useState, useCallback, useEffect } from "react";
import JumboContentLayout from "./../../../../@jumbo/components/JumboContentLayout/JumboContentLayout";
import Header from "./components/Header/Header";
import {
  Card,
  Divider,
  Switch,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import JumboFormLabelTextField from "./../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Div from "./../../../../@jumbo/shared/Div/Div";
import { useJumboDialog } from "./../../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import FieldSelectionForm from "./components/FieldSelectionForm/FieldSelectionForm";
import useFileForm from "./hooks/useFileForm";
import SectionForm from "./components/SectionForm";
import { useFolderApiActions } from "./query/useFolderApiActions";
import { useSelector } from "react-redux";
import FolderHeader from "./components/FolderHeader/FolderHeader";
import { useFetchFolderById } from "./query/useFetchFolderById";

const fieldList = [
  { id: "DropDown", name: "DropDown" },
  { id: "TextField", name: "TextField" },
  { id: "Checkbox", name: "Checkbox" },
  { id: "RadioButton", name: "RadioButton" },
];

function NewContainer() {
  const [sections, setSections] = useState([[]]);

  const { location } = window;

  const id = location.pathname?.split("/")?.[3];

  const { hideDialog, showDialog } = useJumboDialog();
  const { tryCreateFolder, tryUpdateFolder } = useFolderApiActions();
  const { data: folderData, refetch: folderRefetch } = useFetchFolderById(
    id,
    false
  );

  const defaultValues = {
    folderName: "",
    description: "",
    parentFolderId: "",
    isProject: true,
    indexGroup: [{}],
  };

  useEffect(() => {
    if (id) {
      folderRefetch();
    }
  }, [id]);

  useEffect(() => {
    if (folderData) {
      setFieldValue("id", id);
      setFieldValue("folderName", folderData.folderName || "");
      setFieldValue("description", folderData.description || "");
      setFieldValue("isProject", folderData.isProject);
      setSections(
        folderData?.indexGroup?.map((group) => ({
          groupTitle: group.groupTitle,
          indexes: group.indexes.map((field) => ({
            ...field,

            options: field.options || "",
          })),
        })) || [{}]
      );
    }
  }, [folderData]);

  // useEffect(() => {
  //   if (folderData) {
  //     setFieldValue("folderName", folderData.folderName || "");
  //     setFieldValue("description", folderData.description || "");
  //     setFieldValue("isProject", folderData.isProject);
  //     setSections(
  //       folderData?.indexGroup?.map((group) => ({
  //         groupTitle: group.groupTitle,
  //         indexes: group.indexes.map((field) => {
  //           let options = [];
  //           if (field.options) {
  //             try {
  //               // Correct the JSON string format
  //               options = JSON.parse(
  //                 field.options.replace(/([{,])(\w+):/g, '$1"$2":')
  //               );
  //             } catch (error) {
  //               console.error("Error parsing options:", error);
  //             }
  //           }
  //           return {
  //             ...field,
  //             options,
  //           };
  //         }),
  //       })) || [{}]
  //     );
  //   }
  // }, [folderData]);
  // useEffect(() => {
  //   if (folderData) {
  //     // Update form field values based on folderData
  //     setFieldValue("folderName", folderData.folderName || "");
  //     setFieldValue("description", folderData.description || "");
  //     setFieldValue("isProject", folderData.isProject);

  //     // Update sections based on folderData.indexGroup
  //     setSections(
  //       folderData?.indexGroup?.map((group) => ({
  //         groupTitle: group.groupTitle,
  //         indexes: group.indexes.map((field) => {
  //           let options = [];
  //           if (field.options) {
  //             try {
  //               // Attempt to parse the JSON string after correcting the format
  //               options = JSON.parse(
  //                 field.options.replace(/([{,])(\w+):/g, '$1"$2":')
  //               );
  //             } catch (error) {
  //               console.error("Error parsing options:", error);
  //             }
  //           }
  //           return {
  //             ...field,
  //             // Stringify the options back to JSON string
  //             options: JSON.stringify(options),
  //           };
  //         }),
  //       })) || [{}]
  //     );
  //   }
  // }, [folderData]);

  const handleSelectChange = useCallback(
    (index) => (event) => {
      const type = event.target.value;
      setFieldValue(`indexGroup[0].indexes[${index}].type`, type);
      showDialog({
        title: "More Details",
        content: (
          <FieldSelectionForm
            type={type}
            onConfirm={(fieldDetails) => {
              const newSections = sections?.map((section, idx) =>
                idx === index
                  ? [...section, { type, ...fieldDetails }]
                  : section
              );
              setSections(newSections);
              hideDialog();
            }}
          />
        ),
      });
    },
    [sections, showDialog, hideDialog]
  );

  const handleAddSection = () => {
    const newSection = {
      groupTitle: "",
      indexes: [
        {
          fieldName: "",
          type: "",
          options: "",
          required: true,
          actions: [],
        },
      ],
    };
    setFieldValue("indexGroup", [...values.indexGroup, newSection]);
    setSections([...sections, []]);
  };

  const handleActionAdd = (index) => {
    const newSections = [...sections];
    const previousSection = newSections[index];

    if (previousSection) {
      const newSection = {
        ...previousSection,
        groupTitle: "",
      };
      newSections.splice(index, 0, newSection);
      setSections(newSections);
    }
  };

  const updateIndexGroup = (index, updatedGroup) => {
    setFieldValue(`indexGroup[${index}]`, updatedGroup);
  };

  const onSave = (index, updatedGroup) => {
    setFieldValue(`indexGroup[${index}]`, updatedGroup);
    console.log("Form data saved: ", updatedGroup);
  };

  const onSubmit = async (values) => {
    if (id) {
      await tryUpdateFolder(id, values);
    } else {
      await tryCreateFolder(values);
    }

    resetForm();
  };

  const formik = useFileForm(onSubmit, defaultValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <JumboContentLayout
      header={
        <FolderHeader title={"Create Folder"} handleSave={handleSubmit} />
      }
    >
      <Card
        sx={{ display: "flex", mb: 3.5, padding: 4, flexDirection: "column" }}
      >
        <Div
          sx={{ display: "flex", flexDirection: "row", flex: "1", gap: "64px" }}
        >
          <Div sx={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <JumboFormLabelTextField
              fullWidth
              labelStyle={{
                fontFamily: "Inter",
                color: "#000000",
                fontSize: { xs: "1.2rem", md: "16px" },
                fontWeight: 400,
              }}
              label="Folder Name"
              name="folderName"
              placeholder="Enter folder name"
              type="text"
              error={!!touched.folderName && !!errors.folderName}
              helperText={
                (touched.folderName && errors && errors.folderName) || ""
              }
              value={values.folderName}
              onBlur={handleBlur("folderName")}
              onChange={handleChange("folderName")}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={values.isProject}
                  onChange={(e) => handleChange("isProject")(e)}
                  onBlur={handleBlur("isProject")}
                />
              }
              label="Project"
            />
          </Div>
          <JumboFormLabelTextField
            fullWidth
            maxRows={8}
            rows={4}
            name="description"
            multiline
            labelStyle={{
              fontFamily: "Inter",
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Description"
            placeholder=""
            type="text"
            error={!!touched.description && !!errors.description}
            helperText={
              (touched.description && errors && errors.description) || ""
            }
            value={values.description}
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
          />
        </Div>
      </Card>
      {values.isProject && (
        <>
          <Header onAddSection={handleAddSection} onAddAction={onSave} />
          {sections.map((section, index) => (
            <SectionForm
              key={index}
              section={section}
              index={index}
              handleAddSection={handleAddSection}
              handleSelectChange={handleSelectChange}
              fieldList={fieldList}
              updateIndexGroup={updateIndexGroup}
              onSave={onSave}
            />
          ))}
        </>
      )}
    </JumboContentLayout>
  );
}

export default NewContainer;
