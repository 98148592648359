// import React from "react";
// import Div from "@jumbo/shared/Div";
// import AddIcon from "@mui/icons-material/Add";
// import JumboButton from "@jumbo/components/JumboButton";
// import { MenuItem } from "@mui/material";
// import OutlinedSecondaryTextField from "./../../../../../../@jumbo/components/Textfield/index";

// const roleList = [
//   {
//     id: "1",
//     name: "Salman Sheikh",
//   },
//   {
//     id: "2",
//     name: "Riyaz Sheikh",
//   },
//   {
//     id: "3",
//     name: "Saquib Jamal",
//   },
//   {
//     id: "4",
//     name: "Abc",
//   },
// ];

// const Header = ({ onAddSection }) => {
//   const [role, setRole] = React.useState("");
//   return (
//     <Div sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
//       <Div
//         sx={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "space-between",
//           mb: 4,
//           mt: 4,
//         }}
//       >
//         <JumboButton
//           disableElevation
//           variant={"contained"}
//           startIcon={<AddIcon />}
//           sx={{
//             mb: 2,
//             border: "1px solid #371B65",
//             borderRadius: "12px",
//             backgroundColor: " #371B65",
//             fontFamily: "Roboto",
//             fontSize: { lg: " 20px", md: "15px", xs: "12px" },
//             fontWeight: 400,
//             lineHeight: "31px",
//             textAlign: "left",
//             textTransform: "none",
//             "& .MuiSvgIcon-root": {
//               fontSize: "1.5rem",
//             },
//           }}
//           onClick={onAddSection}
//         >
//           Add Section
//         </JumboButton>
//         <Div sx={{ width: { lg: "433px", md: "100%", xs: "100%" } }}>
//           <OutlinedSecondaryTextField
//             focused
//             select
//             label="Select Client"
//             variant="outlined"
//             SelectProps={{
//               displayEmpty: true,
//             }}
//             InputProps={{
//               style: { color: "#9B9B9B" },
//             }}
//             style={{
//               width: "100%",
//               borderRadius: "8px",
//             }}
//           >
//             {roleList.map((item) => (
//               <MenuItem key={item.id} value={item.id}>
//                 {item.name}
//               </MenuItem>
//             ))}
//           </OutlinedSecondaryTextField>
//         </Div>
//         {/* <JumboButton
//           disableElevation
//           variant={"contained"}
//           startIcon={<AddIcon />}
//           onClick={handleSave}
//           sx={{
//             mb: 2,
//             border: "1px solid #371B65",
//             borderRadius: "12px",
//             backgroundColor: " #371B65",
//             fontFamily: "Roboto",
//             fontSize: { lg: " 20px", md: "15px", xs: "12px" },
//             fontWeight: 400,
//             lineHeight: "31px",
//             textAlign: "left",
//             textTransform: "none",
//             "& .MuiSvgIcon-root": {
//               fontSize: "1.5rem",
//             },
//           }}
//         >
//           Save
//         </JumboButton> */}
//       </Div>
//     </Div>
//   );
// };

// export default Header;

import React from "react";
import Div from "@jumbo/shared/Div";
import AddIcon from "@mui/icons-material/Add";
import JumboButton from "@jumbo/components/JumboButton";
import { MenuItem } from "@mui/material";
import OutlinedSecondaryTextField from "./../../../../../../@jumbo/components/Textfield/index";

const roleList = [
  {
    id: "1",
    name: "Salman Sheikh",
  },
  {
    id: "2",
    name: "Riyaz Sheikh",
  },
  {
    id: "3",
    name: "Saquib Jamal",
  },
  {
    id: "4",
    name: "Abc",
  },
];

const Header = ({ onAddSection, onAddAction }) => {
  const [role, setRole] = React.useState("");
  return (
    <Div sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
      <Div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 4,
          mt: 4,
        }}
      >
        <JumboButton
          disableElevation
          variant={"contained"}
          startIcon={<AddIcon />}
          sx={{
            mb: 2,
            border: "1px solid #371B65",
            borderRadius: "12px",
            backgroundColor: " #371B65",
            fontFamily: "Roboto",
            fontSize: { lg: " 20px", md: "15px", xs: "12px" },
            fontWeight: 400,
            lineHeight: "31px",
            textAlign: "left",
            textTransform: "none",
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
            },
          }}
          onClick={onAddSection}
        >
          Add Section
        </JumboButton>
        <JumboButton
          disableElevation
          variant={"contained"}
          startIcon={<AddIcon />}
          sx={{
            mb: 2,
            border: "1px solid #371B65",
            borderRadius: "12px",
            backgroundColor: " #371B65",
            fontFamily: "Roboto",
            fontSize: { lg: " 20px", md: "15px", xs: "12px" },
            fontWeight: 400,
            lineHeight: "31px",
            textAlign: "left",
            textTransform: "none",
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
            },
          }}
          onClick={onAddAction}
        >
          Add Action
        </JumboButton>
        <Div sx={{ width: { lg: "433px", md: "100%", xs: "100%" } }}>
          <OutlinedSecondaryTextField
            focused
            select
            label="Select Client"
            variant="outlined"
            SelectProps={{
              displayEmpty: true,
            }}
            InputProps={{
              style: { color: "#9B9B9B" },
            }}
            style={{
              width: "100%",
              borderRadius: "8px",
            }}
          >
            {roleList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </OutlinedSecondaryTextField>
        </Div>
      </Div>
    </Div>
  );
};

export default Header;
