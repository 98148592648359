import { createSlice } from "@reduxjs/toolkit";

const folderSlice = createSlice({
  name: "folderData",
  initialState: {
    selectedFolderId: null,
  },
  reducers: {
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload;
    },
  },
});

export const { setSelectedFolderId } = folderSlice.actions;
export default folderSlice.reducer;
