import React from "react";

import ImageIcon from "@mui/icons-material/Image";

import { Box } from "@mui/material";
import { getAssetPath } from "./../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../../../utils/constants/paths";
import { blue } from "@mui/material/colors";

function FileIcon(props) {
  const { type } = props;
  //   initializeFileTypeIcons();
  switch (type) {
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "txt":
      return (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            m: 1,
          }}
        >
          <img
            src={getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "32x32")}
            alt="pin"
            width={32}
          />
          {/* <TextSnippetIcon
            sx={{
              fontSize: {
                xs: 30,
                sm: 30,
                md: 45,
                lg: 45,
              },
              color: "#4285f4",
            }}
          /> */}
        </Box>
      );
    case "image/jpeg":
    case "jpeg":
    case "image/png":
      return (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            m: 1,
          }}
        >
          <ImageIcon sx={{ color: blue[500], width: "100%", height: "100%" }} />
        </Box>
      );
    case "text/plain":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            m: 1,
          }}
        >
          <img
            src={getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "32x32")}
            alt="pin"
            width={32}
          />
        </Box>
      );
    case "application/pdf":
    case "./pdf":
      return (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            m: 1,
            // ml: 2,
          }}
        >
          <img
            src={getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "32x32")}
            alt="pin"
            width={32}
          />
        </Box>
      );
    default:
      return (
        <Box
          sx={{
            width: "30px",
            height: "30px",
            m: 1,
          }}
        >
          <ImageIcon sx={{ color: blue[500] }} />
        </Box>
      );
  }
}

export default FileIcon;
