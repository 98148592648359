import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import { ListItem, Grid } from "@mui/material";
import DndWrapper from "./components/DndWrapper";
import { useFetchFolderById } from "../new-container/query/useFetchFolderById";
import JumboFormLabelTextField from "./../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import DynamicFormField from "./components/DynamicFormField/DynamicFormFile";
import Div from "./../../../../@jumbo/shared/Div/Div";
import JumboButton from "./../../../../@jumbo/components/JumboButton/JumboButton";
import useDocumentForm from "./hooks/useDocumentForm";
import { useDocumentApiAction } from "./query/useDocumentApiActions";
import moment from "moment";

const initialValues = {
  fileId: "xyz",
  file: "",
  fileName: "",
  description: "",
  folderId: "",
  indexValues: [],
};

const UploadDoc = () => {
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const { location } = window;

  const id = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "0";

  const versionName = location.pathname.split("/")[4]
    ? decodeURIComponent(location.pathname.split("/")[4])
    : "0";

  const selectedFileId = location.pathname?.split("/")?.[5]
    ? location.pathname?.split("/")?.[5]
    : "0";

  const { data: folderData } = useFetchFolderById(id, true);
  const { tryUploadDocument, tryAddVersions } = useDocumentApiAction();

  const filesName = acceptedFiles.map((file) => (
    <ListItem selected key={file.path} sx={{ width: "auto", mr: 1 }}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));

  const handleDynamicFieldChange = (fieldName, value) => {
    const fieldIndex = values.indexValues?.findIndex(
      (item) => item.name === fieldName
    );

    const newField = { name: fieldName, value: value };

    if (fieldIndex === -1) {
      setFieldValue("indexValues", [...values.indexValues, newField]);
    } else {
      const updatedIndexValues = [...values.indexValues];
      updatedIndexValues[fieldIndex] = newField;
      setFieldValue("indexValues", updatedIndexValues);
    }
  };

  const onSubmit = async (values) => {
    const timestamp = moment().format("YYYYMMDD_HHmmss");

    if (versionName !== "0") {
      const formData = new FormData();
      acceptedFiles?.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileId", selectedFileId);
      formData.append("fileName", versionName);
      formData.append("description", values.description);
      formData.append("folderId", id);
      formData.append("indexValues", JSON.stringify(values.indexValues));

      try {
        await tryAddVersions(formData);
        console.log("Document upload successful");
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    } else {
      const formData = new FormData();
      acceptedFiles?.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("fileId", values.fileId);

      formData.append("fileName", `${values?.fileName}_${timestamp}`);
      // formData.append("fileName", values.fileName);
      formData.append("description", values.description);
      formData.append("folderId", id);
      formData.append("indexValues", JSON.stringify(values.indexValues));

      try {
        await tryUploadDocument(formData);
        console.log("Document upload successful");
      } catch (error) {
        console.error("Error uploading document:", error);
      }
    }
    resetForm();
  };

  const formik = useDocumentForm(onSubmit, initialValues);

  const {
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleBlur,
    handleChange,
    errors,
    resetForm,
  } = formik;

  console.log("errors", errors);

  return (
    <>
      <DndWrapper>
        {/* <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Typography variant={"body1"}>
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </div> */}
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography variant={"body1"}>
            Drag 'n' drop some files here, or click to select files
          </Typography>
        </div>
      </DndWrapper>
      <Typography
        sx={{
          fontFamily: "Inter",
          color: "#000000",
          fontSize: { xs: "1.2rem", md: "16px" },
          fontWeight: 400,
        }}
      >
        Selected File
      </Typography>
      <List disablePadding sx={{ display: "flex", mb: 2 }}>
        {filesName}
      </List>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        <Grid item xs={12} md={7} lg={7}>
          <JumboFormLabelTextField
            labelStyle={{
              fontFamily: "Inter",
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label=" File Name"
            name="fileName"
            value={values.fileName}
            onBlur={handleBlur("fileName")}
            onChange={handleChange("fileName")}
            placeholder="Enter file name"
            type="text"
            disabled={versionName !== "0"}
          />
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <JumboFormLabelTextField
            labelStyle={{
              fontFamily: "Inter",
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            rows={6}
            maxRows={8}
            multiline
            label=" Description"
            name="description"
            value={values.description}
            onBlur={handleBlur("description")}
            onChange={handleChange("description")}
            placeholder="Add description here....."
            type="text"
          />
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          {folderData?.indexGroup &&
            folderData?.indexGroup[0] &&
            folderData?.indexGroup[0]?.indexes?.map((index) => (
              <DynamicFormField
                key={index.id}
                index={index}
                onChange={handleDynamicFieldChange}
              />
            ))}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <Div
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <JumboButton
              fullWidth
              disableElevation
              variant={"contained"}
              sx={{
                mb: 4,
                width: "144px",
                border: "1px solid #371B65",
                borderRadius: "12px",
                backgroundColor: " #371B65",
                fontFamily: "Roboto",
                fontSize: { lg: " 15px", md: "15px", xs: "12px" },
                fontWeight: 400,
                lineHeight: "31px",
                textAlign: "left",
                textTransform: "none",
              }}
              onClick={() => handleSubmit()}
            >
              Upload
            </JumboButton>
          </Div>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadDoc;
