/**
 * @format
 */

import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessages from "@jumbo/constants/ErrorMessages";

const defaultValues = {
  mobile: "",
  firstName: "",
  lastName: "",
  email: "",
};

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const schema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter a valid email address")
    .required(ErrorMessages.login.email),
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid name")
    .max(40, "Name can't be longer than 40 characters")
    .required("Please enter full name"),
  mobile: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .required("Please enter a valid phone number"),
});

const useSignupForm = (onSubmit) => {
  return useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });
};

export default useSignupForm;
