import React, { useState } from "react";
import { Card, CardContent, Typography, TextField } from "@mui/material";
import Link from "@mui/material/Link";
import Div from "@jumbo/shared/Div";
import { ASSET_LOGOS } from "../../../utils/constants/paths";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useAuthApiActions } from "../signup2/query/useAuthApiActions";
import useLoginForm from "./hooks/useForm";
import { Cancel } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import ForgetPasswordForm from "./components/ForgetPasswordForm/ForgetPasswordForm";
import AuthLayout from "app/layouts/auth-layout/AuthLayout";

const defaultValues = {
  email: "",
  password: "",
};

const Login2 = () => {
  const { tryLogin } = useAuthApiActions();
  const { showDialog, hideDialog } = useJumboDialog();
  const navigate = useNavigate();

  const showForgetPasswordDialog = React.useCallback(() => {
    showDialog({
      content: <ForgetPasswordForm hideDialog={hideDialog} />,
    });
  }, []);

  const onSubmit = async (values) => {
    // navigate("/dashboards/misc");
    await tryLogin(values);
  };

  const handleGoToSignup = () => {
    navigate("/user/signup");
  };

  const formik = useLoginForm(onSubmit, defaultValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    isSubmitting,
  } = formik;

  return (
    <AuthLayout
      title={"Store, Secure, and Soar:"}
      description={" Your Digital Vault Awaits"}
    >
      <Div sx={{ mt: { lg: 4, md: 4, xs: 0 } }}>
        <Typography
          fontSize={{ lg: "30px", md: "20px", xs: "15px" }}
          color="#371B65"
          fontWeight={500}
          mb={3}
          lineHeight={"47px"}
          letterSpacing={"5%"}
          fontFamily={"Roboto"}
        >
          Sign In
        </Typography>
      </Div>
      <Div sx={{ mt: 1, mb: 3 }}>
        <TextField
          fullWidth
          error={!!touched.email && !!errors.email}
          helperText={(touched.email && errors && errors.email) || ""}
          sx={{
            width: { lg: "433px", md: "100%", xs: "100%" },
            borderRadius: "8px",
            height: "53px",
            gap: "0px",
            // borderRadius: "8px 0px 0px 0px",
            border: " 1px 0px 0px 0px #AEB4C2",
            opacity: " 0px",
          }}
          name="email"
          label="Email"
          value={values.email}
          onBlur={handleBlur("email")}
          onChange={handleChange("email")}
        />
      </Div>

      <Div
        sx={{ mt: 1, mb: 2, width: { lg: "433px", md: "100%", xs: "100%" } }}
      >
        <Typography
          textAlign={"end"}
          //   variant={"body1"}
          mb={1}
          fontWeight={500}
          fontSize={{ lg: "16px", md: "14px", xs: "12px" }}
          lineHeight={"24px"}
          fontFamily={"Roboto"}
        >
          <Link
            underline="none"
            href="#"
            onClick={showForgetPasswordDialog}
            sx={{ color: "#371B65" }}
          >
            forget password?
          </Link>
        </Typography>

        <TextField
          fullWidth
          error={!!touched.password && !!errors.password}
          helperText={(touched.password && errors && errors.password) || ""}
          sx={{
            width: { lg: "433px", md: "100%", xs: "100%" },
            borderRadius: "8px",
            height: "53px",
            gap: "0px",
            // eslint-disable-next-line no-dupe-keys
            borderRadius: "8px 0px 0px 0px",
            border: " 1px 0px 0px 0px #AEB4C2",
            opacity: " 0px",
            // marginBottom: values.password ? "70px" : "0",
          }}
          name="password"
          label="Password"
          type="password"
          value={values.password}
          onBlur={handleBlur("password")}
          onChange={handleChange("password")}
        />
      </Div>
      <LoadingButton
        fullWidth
        variant="contained"
        size="large"
        sx={{
          mt: "30px",
          backgroundColor: "#371B65",
          width: { lg: "433px", md: "100%", xs: "100%" },
          height: "50px",
          padding: "8.59px 17.17px 8.59px 17.17px",
          gap: "8.59px",
          borderRadius: "4.29px 0px 0px 0px",
          opacity: " 0px",
          fontFamily: "Roboto",
          fontSize: { lg: "17px", md: "14px", xs: "12px" },
          fontWeight: 400,
          lineHeight: "25px",
          textAlign: "left",
          textTransform: "none",
        }}
        loading={isSubmitting}
        onClick={() => handleSubmit()}
      >
        Sign In
      </LoadingButton>
      <Typography
        textAlign={"center"}
        variant={"body1"}
        mb={1}
        mt={"14px"}
        fontWeight={600}
        fontSize={{ lg: "16px", md: "14px", xs: "12px" }}
        lineHeight={"24px"}
        fontFamily={"Roboto"}
      >
        <Link
          underline="none"
          href="#"
          onClick={handleGoToSignup}
          sx={{ color: "#371B65" }}
        >
          Create Account
        </Link>
      </Typography>
    </AuthLayout>
  );
};

export default Login2;
