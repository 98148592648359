/**
 * @format
 */

import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

const defaultValues = {
  fileId: "xyz",
  file: "",
  fileName: "",
  description: "",
  folderId: "",
  indexValues: {},
};

const schema = Yup.object().shape({
  description: Yup.string().required("please enter description"),
});

const useDocumentForm = (onSubmit, initialValues = defaultValues) => {
  return useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useDocumentForm;
