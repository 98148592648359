import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  restoreFiles,
  permanentDeleteFile,
} from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { useSnackbar } from "notistack";

const useTrashApiAction = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tryRestoreFile = async (ids, type) => {
    try {
      const response = await restoreFiles(ids, type);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.recycleFiles]);
        queryClient.invalidateQueries([QueryKeys.document]);
        enqueueSnackbar("File restore successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });

      return false;
    }
  };

  const tryPermanentDeleteFile = async (value) => {
    try {
      const response = await permanentDeleteFile(value);
      console.log("response", response);
      if (response.message === "deleted successfully!") {
        queryClient.invalidateQueries([QueryKeys.recycleFiles]);
        queryClient.invalidateQueries([QueryKeys.document]);
        enqueueSnackbar("File deleted successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });

      return false;
    }
  };

  return {
    tryPermanentDeleteFile,
    tryRestoreFile,
  };
};

export { useTrashApiAction };
