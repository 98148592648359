import { useFormik } from "formik";
import * as Yup from "yup";

const defaultValues = {
  indexes: [
    {
      name: "",
      type: "",
      options: "",
      required: true,
    },
  ],
};

const schema = Yup.object().shape({});

const useSectionFormItem = (onSubmit, initialValues = defaultValues) => {
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });

  const handleChange = (fieldName) => (event) => {
    formik.handleChange(event);
    formik.setFieldTouched(fieldName, true, false);
  };

  return { ...formik, handleChange };
};

export default useSectionFormItem;
