import React from "react";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ListIcon from "@mui/icons-material/List";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { getAssetPath } from "./../../../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../../../utils/constants/paths";

const menus = [
  {
    label: "sidebar.menu.home",
    type: "section",
    children: [
      {
        uri: "/dashboards/misc",
        label: "sidebar.menuItem.allFiles",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/allFileIcon1.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },

      {
        uri: "/dashboards/my-files",
        label: "sidebar.menuItem.myFiles",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/myFiles.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },
      {
        uri: "/dashboards/shared-files",
        label: "sidebar.menuItem.sharedFiles",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/shareFileIcon.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },
      {
        uri: "/dashboards/favorite-files",
        label: "sidebar.menuItem.favorites",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/favoriteFileIcon.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },

      {
        uri: "/dashboards/trash-files",
        label: "sidebar.menuItem.recycleBin",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/trashFileIcon.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },

      // {
      //   uri: "/dashboards/packages",
      //   label: "sidebar.menuItem.crypto",
      //   type: "nav-item",
      //   icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: 20 }} />,
      // },
      {
        uri: "/dashboards/users",
        label: "sidebar.menuItem.users",
        type: "nav-item",
        icon: <ListIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/dashboards/settings",
        label: "sidebar.menuItem.settings",
        type: "nav-item",
        icon: <SettingsIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/dashboards/workspace",
        label: "Workspace",
        type: "nav-item",
        icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },

  {
    label: "sidebar.menu.apps",
    type: "section",
    children: [
      {
        uri: "/app/chats",
        label: "Admin Console",
        type: "nav-item",
        icon: (
          <img
            src={getAssetPath(`${ASSET_IMAGES}/adminConsole.png`, "20x20")}
            alt="pin"
            width={20}
          />
        ),
      },
    ],
  },

  {
    label: "sidebar.menu.components",
    type: "section",
    children: [
      {
        label: "Folder",
        type: "collapsible",

        children: [
          {
            uri: "/mui/accordions",
            label: "Finance",
            type: "collapsible",
            icon: (
              <img
                src={getAssetPath(
                  `${ASSET_IMAGES}/sidebarfolderIcon.png`,
                  "20x20"
                )}
                alt="pin"
                width={20}
              />
            ),
          },
        ],
      },
    ],
  },
];

export default menus;
