import React from "react";
import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { FormControlLabel, Switch } from "@mui/material";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import JumboButton from "./../../../../../../@jumbo/components/JumboButton/JumboButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";

const FieldSelectionForm = ({ type, onConfirm, onCancel }) => {
  const [fieldDetails, setFieldDetails] = React.useState({
    name: "",
    options: [],
    // options: [{ value: "" }],
    required: false,
  });

  const [newOptionValue, setNewOptionValue] = React.useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleOptionChange = (index) => (e) => {
    const newOptions = fieldDetails.options.map((option, i) =>
      i === index ? { value: e.target.value } : option
    );
    setFieldDetails((prevDetails) => ({
      ...prevDetails,
      options: newOptions,
    }));
  };

  const addOption = () => {
    if (newOptionValue.trim() !== "") {
      setFieldDetails((prevDetails) => ({
        ...prevDetails,
        options: [...prevDetails.options, { value: newOptionValue }],
      }));
      setNewOptionValue("");
    }
  };

  const removeOption = (index) => () => {
    setFieldDetails((prevDetails) => ({
      ...prevDetails,
      options: prevDetails.options.filter((_, i) => i !== index),
    }));
  };

  const handleSwitchChange = (e) => {
    const { checked } = e.target;
    setFieldDetails((prevDetails) => ({
      ...prevDetails,
      required: checked,
    }));
  };

  const handleSubmit = () => {
    if (fieldDetails.name.trim() === "") {
      alert("Field name is required");
      return;
    }
    if (
      (type === "DropDown" || type === "Checkbox" || type === "RadioButton") &&
      fieldDetails.options.length === 0
    ) {
      alert("At least one option is required");
      return;
    }

    const formattedOptions = `[${fieldDetails.options
      .map((option) => `{value:\"${option.value.trim()}\"}`)
      .join(",")}]`;

    const formattedFieldDetails = {
      ...fieldDetails,
      options: formattedOptions,
    };

    onConfirm(formattedFieldDetails);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      // Reset form logic
      setFieldDetails({
        name: "",
        options: [{ value: "" }],
        required: false,
      });
      setNewOptionValue("");
    }
  };

  return (
    <Box>
      <JumboFormLabelTextField
        fullWidth
        label="Label"
        name="name"
        value={fieldDetails.name}
        onChange={handleInputChange}
        placeholder="Enter Label"
        type="text"
      />

      {(type === "DropDown" ||
        type === "Checkbox" ||
        type === "RadioButton") && (
        <>
          <JumboFormLabelTextField
            iconEnd
            fullWidth
            label="Option"
            name="option"
            placeholder="Enter Option"
            value={newOptionValue}
            onChange={(e) => setNewOptionValue(e.target.value)}
            type="text"
            icon={<AddIcon />}
            onClickEndIcon={addOption}
          />
          {fieldDetails.options.length > 0 && (
            <List>
              {fieldDetails.options.map((option, index) => (
                <ListItem key={index}>
                  <JumboFormLabelTextField
                    fullWidth
                    value={option.value}
                    onChange={handleOptionChange(index)}
                    type="text"
                  />
                  <IconButton edge="end" onClick={removeOption(index)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          )}
          {/* <List>
            {fieldDetails.options.map((option, index) => (
              <ListItem key={index}>
                <JumboFormLabelTextField
                  fullWidth
                  value={option.value}
                  onChange={handleOptionChange(index)}
                  type="text"
                />
                <IconButton edge="end" onClick={removeOption(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List> */}
        </>
      )}

      <FormControlLabel
        control={
          <Switch
            checked={fieldDetails.required}
            onChange={handleSwitchChange}
          />
        }
        label="Required"
      />

      <Div sx={{ display: "flex", justifyContent: "flex-end" }}>
        <JumboButton
          variant="contained"
          onClick={handleSubmit}
          sx={{
            border: "1px solid #371B65",
            borderRadius: "8px",
            backgroundColor: "#371B65",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "10px" },
            fontWeight: 400,
            lineHeight: "31px",
            textAlign: "left",
            textTransform: "none",
            mr: 2,
          }}
        >
          Save
        </JumboButton>
        <JumboButton
          variant="outlined"
          onClick={handleCancel}
          sx={{
            border: "1px solid #371B65",
            borderRadius: "8px",
            color: "#371B65",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "10px" },
            fontWeight: 400,
            lineHeight: "31px",
            textAlign: "left",
            textTransform: "none",
          }}
        >
          Cancel
        </JumboButton>
      </Div>
    </Box>
  );
};

export default FieldSelectionForm;
