import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  addUser,
  editUser,
  deleteUser,
} from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";

const useUserApiAction = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const tryAddUser = async (values) => {
    try {
      const response = await addUser(values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.users]);

        enqueueSnackbar("User added successfully.", {
          variant: "success",
        });
        navigate("/dashboards/users");
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryEditUser = async (id, values) => {
    try {
      const response = await editUser(id, values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.users]);

        enqueueSnackbar("User Edit successfully.", {
          variant: "success",
        });
        navigate("/dashboards/users");
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryDeleteUser = async (id) => {
    try {
      const response = await deleteUser(id);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.users]);
        enqueueSnackbar("User Deleted successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  return {
    tryDeleteUser,
    tryAddUser,
    tryEditUser,
  };
};

export { useUserApiAction };
