/**
 * @format
 */

import { useFormik } from "formik";
import * as Yup from "yup";

const defaultValues = {
  description: "",
  folderName: "",
  parentFolderId: "",
  isProject: true,
  indexGroup: [],
};

const schema = Yup.object().shape({
  folderName: Yup.string().required("Please enter a valid folder name"),
  description: Yup.string().required("Please enter a valid company"),
});

const useFileForm = (onSubmit) => {
  return useFormik({
    initialValues: defaultValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: onSubmit,
  });
};

export default useFileForm;
