// import React, { useState, useEffect } from "react";
// import { Card, Divider } from "@mui/material";
// import Div from "./../../../../../../@jumbo/shared/Div/Div";
// import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
// import useSectionForm from "./../../hooks/useSectionForm";
// import JumboButton from "./../../../../../../@jumbo/components/JumboButton/JumboButton";
// import { useSectionFormActions } from "../../../../../redux/sectionData/sectionData.slice";
// import SectionFormItem from "./SectionFormItem";

// function SectionForm({
//   section,
//   index,
//   handleSelectChange,
//   fieldList,
//   onSave,
// }) {
//   const [groupTitle, setGroupTitle] = useState(section.groupTitle || "");
//   const [indexes, setIndexes] = useState(section.indexes || section || []);

//   console.log("section", section);

//   useEffect(() => {
//     if (section.groupTitle) {
//       setGroupTitle(section.groupTitle);
//     }
//   }, [section.groupTitle]);

//   useEffect(() => {
//     if (section.indexes) {
//       setIndexes(section.indexes);
//     } else {
//       setIndexes(section);
//     }
//   }, [section.indexes, section]);

//   const onSubmit = async (values) => {
//     const updatedGroup = {
//       groupTitle: groupTitle,

//       indexes: indexes.map((field) => ({
//         name: field?.name || field?.fieldName,
//         required: field.required,
//         type: field?.type,
//         options: field?.options,
//       })),
//     };

//     onSave(index, updatedGroup);
//     console.log("updatedForm", updatedGroup);
//   };

//   const formik = useSectionForm(onSubmit, {
//     indexGroup: [
//       {
//         groupTitle: groupTitle,
//         indexes: indexes,
//       },
//     ],
//   });

//   const {
//     handleBlur,
//     handleChange,
//     handleSubmit,
//     values,
//     errors,
//     touched,
//     setFieldValue,
//   } = formik;

//   const handleIndexChange = (fieldIdx, field) => (e) => {
//     const updatedIndexes = indexes?.map((item, idx) =>
//       idx === fieldIdx ? { ...item, [field]: e.target.value } : item
//     );
//     setIndexes(updatedIndexes);
//     setFieldValue(`indexGroup[${index}].indexes`, updatedIndexes);
//   };

//   console.log("indexes", indexes);

//   return (
//     <>
//       <Card
//         sx={{
//           display: "flex",
//           mb: 3.5,
//           padding: 4,
//           flexDirection: "column",
//           justifyContent: "center",
//         }}
//       >
//         <Div
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             flex: "1",
//             gap: "20px",
//           }}
//         >
//           <JumboFormLabelTextField
//             fullWidth
//             labelStyle={{
//               fontFamily: "Inter",
//               color: "#000000",
//               fontSize: { xs: "1.2rem", md: "16px" },
//               fontWeight: 400,
//             }}
//             label="Group Title"
//             name={`indexGroup[${index}].groupTitle`}
//             placeholder="Enter group title"
//             type="text"
//             value={groupTitle}
//             onBlur={handleBlur(`indexGroup[${index}].groupTitle`)}
//             onChange={(e) => {
//               setGroupTitle(e.target.value);
//               setFieldValue(`indexGroup[${index}].groupTitle`, e.target.value);
//             }}
//           />
//           <JumboFormLabelTextField
//             fullWidth
//             isSelect
//             menuItems={fieldList}
//             labelStyle={{
//               fontFamily: "Inter",
//               color: "#000000",
//               fontSize: { xs: "1.2rem", md: "16px" },
//               fontWeight: 400,
//             }}
//             label="Field Type"
//             name={`indexGroup[${index}].indexes[${index}].type`}
//             placeholder=""
//             type="text"
//             onSelectHandler={handleSelectChange(index)}
//             value={values.indexGroup?.[0]?.indexes?.[index]?.type || ""}
//             onBlur={handleBlur(`indexGroup[${index}].indexes[${index}].type`)}
//             onChange={handleChange(
//               `indexGroup[${index}].indexes[${index}].type`
//             )}
//           />
//         </Div>

//         <Divider sx={{ mt: 2 }} />
//         <Div
//           sx={{
//             display: "flex",
//             flexDirection: "row",
//             flex: "1",
//             gap: "64px",
//             mt: 2,
//           }}
//         >
//           {indexes?.map((field, fieldIdx) => (
//             <Div key={fieldIdx} sx={{ flex: 1 }}>
//               <SectionFormItem
//                 field={field}
//                 fieldIdx={fieldIdx}
//                 onFieldChange={handleIndexChange(fieldIdx, "fieldName")}
//               />
//             </Div>
//           ))}
//         </Div>

//         <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
//           <JumboButton
//             disableElevation
//             variant={"contained"}
//             sx={{
//               border: "1px solid #371B65",
//               borderRadius: "12px",
//               backgroundColor: " #371B65",
//               fontFamily: "Roboto",
//               fontSize: { lg: " 20px", md: "15px", xs: "12px" },
//               fontWeight: 400,
//               lineHeight: "31px",
//               textAlign: "left",
//               textTransform: "none",
//               "& .MuiSvgIcon-root": {
//                 fontSize: "1.5rem",
//               },
//             }}
//             onClick={() => handleSubmit()}
//           >
//             Save
//           </JumboButton>
//         </Div>
//       </Card>
//     </>
//   );
// }

// export default SectionForm;

import React, { useState, useEffect } from "react";
import { Card, Divider, Grid } from "@mui/material";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import useSectionForm from "./../../hooks/useSectionForm";
import JumboButton from "./../../../../../../@jumbo/components/JumboButton/JumboButton";
import { useSectionFormActions } from "../../../../../redux/sectionData/sectionData.slice";
import SectionFormItem from "./SectionFormItem";

function SectionForm({
  section,
  index,
  handleSelectChange,
  fieldList,
  onSave,
}) {
  const [groupTitle, setGroupTitle] = useState(section.groupTitle || "");
  const [indexes, setIndexes] = useState(section.indexes || section || []);

  useEffect(() => {
    if (section.groupTitle) {
      setGroupTitle(section.groupTitle);
    }
  }, [section.groupTitle]);

  useEffect(() => {
    if (section.indexes) {
      setIndexes(section.indexes);
    } else {
      setIndexes(section);
    }
  }, [section.indexes, section]);

  const onSubmit = async (values) => {
    const updatedGroup = {
      groupTitle: groupTitle,
      indexes: indexes.map((field) => ({
        fieldName: field?.name || field?.fieldName,
        required: field.required,
        type: field?.type,
        options: field?.options,
      })),
    };

    onSave(index, updatedGroup);
  };

  const formik = useSectionForm(onSubmit, {
    indexGroup: [
      {
        groupTitle: groupTitle,
        indexes: indexes,
      },
    ],
  });

  const { handleBlur, handleChange, handleSubmit, values, setFieldValue } =
    formik;

  const handleIndexChange = (fieldIdx, field) => (e) => {
    const updatedIndexes = indexes?.map((item, idx) =>
      idx === fieldIdx ? { ...item, [field]: e.target.value } : item
    );
    setIndexes(updatedIndexes);
    setFieldValue(`indexGroup[${index}].indexes`, updatedIndexes);
  };

  return (
    <>
      <Card
        sx={{
          display: "flex",
          mb: 3.5,
          padding: 4,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            gap: "20px",
          }}
        >
          <JumboFormLabelTextField
            fullWidth
            labelStyle={{
              fontFamily: "Inter",
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Group Title"
            name={`indexGroup[${index}].groupTitle`}
            placeholder="Enter group title"
            type="text"
            value={groupTitle}
            onBlur={handleBlur(`indexGroup[${index}].groupTitle`)}
            onChange={(e) => {
              setGroupTitle(e.target.value);
              setFieldValue(`indexGroup[${index}].groupTitle`, e.target.value);
            }}
          />
          <JumboFormLabelTextField
            fullWidth
            isSelect
            menuItems={fieldList}
            labelStyle={{
              fontFamily: "Inter",
              color: "#000000",
              fontSize: { xs: "1.2rem", md: "16px" },
              fontWeight: 400,
            }}
            label="Field Type"
            name={`indexGroup[${index}].indexes[${index}].type`}
            placeholder=""
            type="text"
            value={values.indexGroup?.[0]?.indexes?.[index]?.type || ""}
            onBlur={handleBlur(`indexGroup[${index}].indexes[${index}].type`)}
            onChange={(e) => {
              const selectedValue = e.target.value;
              const updatedIndexes = indexes.map((item, idx) =>
                idx === index ? { ...item, type: selectedValue } : item
              );
              setIndexes(updatedIndexes);
              setFieldValue(
                `indexGroup[${index}].indexes[${index}].type`,
                selectedValue
              );
            }}
            onSelectHandler={handleSelectChange(index)}
          />
        </Div>

        <Divider sx={{ mt: 2 }} />
        <Grid container spacing={3.75} sx={{ width: "100%" }}>
          {/* <Div
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: "1",
            gap: "64px",
            mt: 2,
          }}
        > */}
          {indexes?.map((field, fieldIdx) => (
            // <Div key={fieldIdx} sx={{ flex: 1 }}>
            <Grid item xs={12} md={6} lg={6} key={fieldIdx}>
              <SectionFormItem
                field={field}
                fieldIdx={fieldIdx}
                onFieldChange={handleIndexChange(fieldIdx, "fieldName")}
              />
            </Grid>
          ))}
        </Grid>

        <Div sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <JumboButton
            disableElevation
            variant={"contained"}
            sx={{
              border: "1px solid #371B65",
              borderRadius: "12px",
              backgroundColor: " #371B65",
              fontFamily: "Roboto",
              fontSize: { lg: " 20px", md: "15px", xs: "12px" },
              fontWeight: 400,
              lineHeight: "31px",
              textAlign: "left",
              textTransform: "none",
              "& .MuiSvgIcon-root": {
                fontSize: "1.5rem",
              },
            }}
            onClick={() => handleSubmit()}
          >
            Save
          </JumboButton>
        </Div>
      </Card>
    </>
  );
}

export default SectionForm;
