import React, { useState } from "react";
import {
  Grid,
  Breadcrumbs,
  Typography,
  ButtonGroup,
  Box,
  Link,
} from "@mui/material";
import { getAssetPath } from "./../../utils/appHelpers";
import { ASSET_IMAGES } from "./../../utils/constants/paths";
import UserOrders from "./UserOrders/UserOrders";
import OnSelectDocument from "./OnSelectDocument/OnSelectDocument";
import FileButton from "./FileButton/FileButton";
import Div from "@jumbo/shared/Div";
import JumboButton from "@jumbo/components/JumboButton";
import MyFiles from "./../../pages/dashboards/my-files/MyFiles";

const DashboardLayout = ({
  children,
  selectedUsers,
  isFolderView,
  isAllFiles,
  isRecycle,
  isShareFiles,
  isFileDetail,
}) => {
  const wordIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/wordicon.png`, "41x41");
  const XcelIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/xcelicon.png`, "41x41");
  const pptIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/ppticon.png`, "41x41");
  const pdfIcon = isFolderView
    ? getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "41x41")
    : getAssetPath(`${ASSET_IMAGES}/pdfIcon.png`, "41x41");

  return (
    <>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        {/* {isRecycle ? (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container justifyContent="space-between">
                <Grid item xs={2} md={2} lg={2}>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: { lg: "20px", md: "16px", xs: "14px" },
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#000000",
                    }}
                  >
                    Recycle
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2} lg={2}>
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <JumboButton
                      startIcon={
                        <img
                          src={getAssetPath(
                            `${ASSET_IMAGES}/files.png`,
                            "18x18"
                          )}
                          alt="pin"
                          width={18}
                        />
                      }
                      sx={{
                        width: "91px",
                        height: "32px",
                        padding: "6px 16px 6px 16px",
                        gap: "8px",
                        backgroundColor: "#371B65",
                        textTransform: "none",
                        fontFamily: "Roboto",
                        fontSize: { lg: "14px", md: "12px", xs: "10px" },
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: "0.05em",
                      }}
                    >
                      Files
                    </JumboButton>
                    <JumboButton
                      variant="outlined"
                      startIcon={
                        <img
                          src={getAssetPath(
                            `${ASSET_IMAGES}/folder.png`,
                            "18x18"
                          )}
                          alt="pin"
                          width={18}
                        />
                      }
                      sx={{
                        width: "101px",
                        height: "32px",
                        padding: "6px, 16px, 6px, 16px",
                        gap: "8px",
                        color: "#371B65",
                        border: "1px solid #371B65",
                        textTransform: "none",
                        fontFamily: "Roboto",
                        fontSize: { lg: "14px", md: "12px", xs: "10px" },
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: "0.05em",
                      }}
                    >
                      Folder
                    </JumboButton>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {children}
            </Grid>
          </>
        ) : ( */}
        <>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: { md: "16px", lg: "20px" },
                fontWeight: 400,
                lineHeight: "20px",
                color: "#000000",
              }}
            >
              Pinned Files
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <UserOrders iconSrc={wordIcon} />
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <UserOrders iconSrc={XcelIcon} />
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <UserOrders iconSrc={pptIcon} />
          </Grid>
          <Grid item xs={6} lg={3} md={3}>
            <UserOrders iconSrc={pdfIcon} />
          </Grid>

          {isAllFiles && (
            <>
              <Grid item xs={6} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    // padding: "15px 114.55px 12px 24px",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    backgroundColor: " #371B65",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "12px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"contained"}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/addButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                >
                  Add New
                </JumboButton>
              </Grid>
              <Grid item xs={6} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    // padding: "15px 114.55px 12px 24px",
                    color: "#371B65",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "12px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"outlined"}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/uploadButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                >
                  Upload
                </JumboButton>
              </Grid>
              <Grid item xs={12} lg={4} md={4}>
                <JumboButton
                  fullWidth
                  sx={{
                    height: "58px",
                    // padding: "15px 80px 12px 24px",
                    border: "1px solid #371B65",
                    borderRadius: "12px",
                    color: "#371B65",
                    fontFamily: "Roboto",
                    fontSize: { lg: " 20px", md: "15px", xs: "10px" },
                    fontWeight: 400,
                    lineHeight: "31px",
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  variant={"outlined"}
                  startIcon={
                    <img
                      src={getAssetPath(
                        `${ASSET_IMAGES}/folderButton.png`,
                        "24x24"
                      )}
                      alt="pin"
                      width={24}
                    />
                  }
                >
                  Add Shared Folder
                </JumboButton>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: { md: "16px", lg: "20px" },
                fontWeight: "400",
                lineHeight: "20px",
                color: "#000000",
              }}
            >
              {isFileDetail ? (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="/">
                    MyFiles
                  </Link>
                  <Link
                    underline="hover"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                  >
                    Daily task tracking
                  </Link>
                  <Typography color="text.primary">Breadcrumbs</Typography>
                </Breadcrumbs>
              ) : (
                "All Files"
              )}
            </Typography>
          </Grid>
          {!isFileDetail && (
            <Grid item xs={12} md={12} lg={12}>
              {selectedUsers.length > 0 ? (
                <OnSelectDocument />
              ) : (
                <FileButton
                  isFolderView
                  isAllFiles={isAllFiles}
                  isShareFiles={isShareFiles}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={12}>
            {children}
          </Grid>
        </>
        {/* )} */}
      </Grid>
    </>
  );
};

DashboardLayout.defaultProps = {
  isRecycle: false,
};
export default DashboardLayout;
