import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import {
  Grid,
  Tabs,
  Tab,
  Card,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import JumboButton from "@jumbo/components/JumboButton";
import JumboFormLabelTextField from "../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import React, { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useFetchFileById } from "./query/useFetchFileById";
import { Divider } from "@mui/material";
import FileIcon from "./../workspace/components/FileIcon/index";
import moment from "moment";

function FileDetail() {
  const [tabIndex, setTabIndex] = useState(0);
  const { location } = window;

  const id = location.pathname?.split("/")?.[3]
    ? location.pathname?.split("/")?.[3]
    : "0";

  console.log("id", id);

  const { data: fileData } = useFetchFileById(id);

  console.log("data ", fileData);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const renderFile = () => {
    if (!fileData || !fileData.fileUrl?.fileUrl) return null;

    const fileURL = fileData.fileUrl?.fileUrl;
    const fileType = fileData.fileType;

    if (fileType.startsWith("image/jpeg") || fileType.startsWith("image/png")) {
      return (
        <img
          src={fileURL}
          alt="File"
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <object
          data={fileURL}
          type="application/pdf"
          style={{ width: "100%", height: "100%" }}
        >
          <a href={fileURL} target="_blank" rel="noopener noreferrer">
            Download the PDF
          </a>
        </object>
      );
    } else if (
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      fileType === "text/plain"
    ) {
      return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(
            fileURL
          )}&embedded=true`}
          style={{ width: "100%", height: "100%" }}
          frameBorder="0"
        ></iframe>
      );
    } else {
      return <p>Cannot preview this file type. Please download to view.</p>;
    }
  };

  if (!fileData) {
    return <div>File not found</div>;
  }

  return (
    <DashboardLayout isFolderView={false} isFileDetail={true}>
      <Grid container spacing={3.75} sx={{ width: "100%" }}>
        <Grid item xs={12} md={12} lg={8}>
          <Card
            sx={{
              padding: { xs: "1rem", sm: "1rem", md: "0px" },
              mr: "22px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FCFBF6",
              position: "relative",
              borderRadius: "11px",
              height: "100%",
            }}
          >
            {renderFile()}
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Card sx={{ borderRadius: "8px" }}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#371B65",
                  color: "#371B65",
                },
              }}
              variant="fullWidth"
            >
              <Tab
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: "13.12px",
                  fontWeight: 600,
                  lineHeight: "18.95px",
                  color: "#2E4448",
                }}
                label="Properties"
              />
              <Tab
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: "13.12px",
                  fontWeight: 600,
                  lineHeight: "18.95px",
                  color: "#2E4448",
                }}
                label="Version"
              />
              <Tab
                sx={{
                  textTransform: "none",
                  fontFamily: "Roboto",
                  fontSize: "13.12px",
                  fontWeight: 600,
                  lineHeight: "18.95px",
                  color: "#2E4448",
                }}
                label="History"
              />
            </Tabs>
          </Card>
          <Card
            borderRadius="11px"
            sx={{
              padding: "27px 60px 20px 28px",
              border: "1px solid #FCFBF6",
              backgroundColor: "#FFFFFF",
              gap: "0px 24px",
              alignItems: "center",
            }}
          >
            {tabIndex === 0 && (
              <React.Fragment>
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Po Date"
                  name="poDate"
                  placeholder="Enter PO date"
                  type="text"
                  defaultValue={fileData.poDate}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Customer Number"
                  name="customerNumber"
                  placeholder="Enter customer number"
                  type="text"
                  defaultValue={fileData.customerNumber}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Expiry On"
                  name="expiryOn"
                  placeholder="Expiry On"
                  type="date"
                  defaultValue={fileData.expiryOn}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Status"
                  name="status"
                  placeholder="Enter status"
                  type="text"
                  defaultValue={fileData.status}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Premium amount"
                  name="premiumAmount"
                  placeholder="Enter amount"
                  type="text"
                  defaultValue={fileData.premiumAmount}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Plan"
                  name="plan"
                  placeholder="Enter plan"
                  type="text"
                  defaultValue={fileData.plan}
                />
                <JumboFormLabelTextField
                  fullWidth
                  labelStyle={{
                    fontFamily: "Inter",
                    color: "#000000",
                    fontSize: { xs: "1.2rem", md: "16px" },
                    fontWeight: 400,
                  }}
                  label="Certificate"
                  name="certificate"
                  placeholder="Enter certificate name"
                  type="text"
                  defaultValue={fileData.certificate}
                />
                <Box
                  sx={{
                    mt: 1,
                    ml: 0,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "start",
                    justifyContent: "end",
                  }}
                >
                  <JumboButton
                    sx={{
                      padding: "8px 16px 8px 16px",
                      gap: "8px",
                      borderRadius: " 4px ",
                      border: "1px solid #371B65",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      color: "#371B65",
                      textTransform: "none",
                    }}
                    variant="outlined"
                  >
                    Reset
                  </JumboButton>
                  <JumboButton
                    sx={{
                      textTransform: "none",
                      padding: "8px 16px 8px 16px",
                      gap: "8px",
                      borderRadius: " 4px ",
                      border: "1px solid #371B65",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                      backgroundColor: "#371B65",
                      color: "#FCFBF6",
                    }}
                    variant="contained"
                  >
                    Save
                  </JumboButton>
                </Box>
              </React.Fragment>
            )}
            {tabIndex === 1 &&
              fileData?.versions?.map((version, index) => {
                return (
                  <div key={index}>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        p: 2,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem disableGutters>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <ListItemText primary="Icon" />
                          </Grid>
                          <Grid item xs={8}>
                            <FileIcon type={version.fileType} />
                            {/* <img
                              src={`${ASSET_IMAGES}/logo.png`}
                              alt="Jumbo React"
                            /> */}
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem disableGutters>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <ListItemText primary="File Name" />
                          </Grid>
                          <Grid item xs={8}>
                            <ListItemText primary={version.fileName} />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem disableGutters>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <ListItemText primary="Version no" />
                          </Grid>
                          <Grid item xs={8}>
                            <ListItemText primary={version.version} />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem disableGutters>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <ListItemText primary="Date" />
                          </Grid>
                          <Grid item xs={8}>
                            <ListItemText
                              primary={moment(version?.createdAt).format(
                                "DD/MM/YYYY h:mm A"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      <ListItem disableGutters>
                        <Grid container alignItems="center">
                          <Grid item xs={4}>
                            <ListItemText primary="Time" />
                          </Grid>
                          <Grid item xs={8}>
                            <ListItemText
                              primary={moment(version?.updatedAt).format(
                                "DD/MM/YYYY h:mm A"
                              )}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                  </div>
                );
              })}

            {tabIndex === 2 &&
              (fileData?.chkInChkOutHistory &&
              fileData.chkInChkOutHistory.length > 0 ? (
                fileData.chkInChkOutHistory.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            p: 2,
                            bgcolor: "background.paper",
                          }}
                        >
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="User Name" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText primary={data.UserName} />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Status" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText
                                  primary={data.checkInCheckOutStatus}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem disableGutters>
                            <Grid container alignItems="center">
                              <Grid item xs={4}>
                                <ListItemText primary="Time" />
                              </Grid>
                              <Grid item xs={8}>
                                <ListItemText
                                  primary={moment(data?.createdAt).format(
                                    "DD/MM/YYYY h:mm A"
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </div>
                      <Divider />
                    </React.Fragment>
                  );
                })
              ) : (
                <Typography
                  variant="body1"
                  sx={{ justifyContent: "center", p: 2 }}
                >
                  No history available
                </Typography>
              ))}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default FileDetail;
