import React from "react";
import Div from "@jumbo/shared/Div";
import AddIcon from "@mui/icons-material/Add";
import JumboButton from "@jumbo/components/JumboButton";
import { MenuItem, Typography } from "@mui/material";

const FolderHeader = ({ title, handleSave }) => {
  return (
    <Div sx={{ display: "flex", flexDirection: "column", mb: 4 }}>
      <Div
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 4,
          mt: 4,
        }}
      >
        <Typography variant={"h2"}>{title}</Typography>

        <JumboButton
          disableElevation
          variant={"contained"}
          onClick={handleSave}
          sx={{
            mb: 2,
            border: "1px solid #371B65",
            borderRadius: "12px",
            backgroundColor: " #371B65",
            fontFamily: "Roboto",
            fontSize: { lg: " 20px", md: "15px", xs: "12px" },
            fontWeight: 400,
            lineHeight: "31px",
            textAlign: "left",
            textTransform: "none",
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
            },
          }}
        >
          Save
        </JumboButton>
      </Div>
    </Div>
  );
};

export default FolderHeader;
