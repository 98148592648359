import React, { useState } from "react";
import { users } from "../../data";
import UserItem from "./UserItem";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import TablePagination from "@mui/material/TablePagination";
import PageHeader from "../../../../../layouts/shared/headers/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { useFetchAllUsers } from "../../query/useFetchAllUser";
import useDecodedData from "app/hooks/useDecodedData";

const UsersList = () => {
  const navigate = useNavigate();

  const [userList, setUserList] = useState(users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const decode = useDecodedData();
  console.log("decode", decode);

  const { data: userData } = useFetchAllUsers();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      //   refetch();
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTimeout(() => {
      //   refetch();
    }, 500);
  };
  const handleAddUser = () => {
    navigate(`/dashboards/users/add-user`);
  };

  return (
    <JumboContentLayout
      header={
        <PageHeader
          title={"Users"}
          showAddContactDialog={handleAddUser}
          isAddButton
          buttonText={"Add User"}
        />
      }
    >
      {userData?.map((user, index) => (
        <UserItem
          user={user}
          key={index}
          //   deleteUser={() => handleDeleteUser(user.id)}
        />
      ))}

      <TablePagination
        component="div"
        count={30 || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[20, 30, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </JumboContentLayout>
  );
};
export default UsersList;
