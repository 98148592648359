import React, { useEffect, useState } from "react";
import { Button, Typography, Box, Divider } from "@mui/material";
import useRoleForm from "../hooks/useRoleForm";
import Div from "@jumbo/shared/Div";
import { Switch, FormControlLabel } from "@mui/material";
import { useRoleApiActions } from "../query/useRoleApiActions";
import useDecodedData from "app/hooks/useDecodedData";
import JumboFormLabelTextField from "./../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import JumboCheckbox from "./../../../../../@jumbo/components/JumboCheckbox/JumboCheckbox";
import { useFetchAllControl } from "../query/useFetchAllControl";
import JumboCustomLoader from "./../../../../../@jumbo/components/JumboLoader/JumboCustomLoader";

const initialValues = {
  roleName: "",
  controls: [],
  isFullAccess: false,
  status: true,
};

const RoleForm = ({
  user,
  handleUpdateRole,
  handleCreateRole,
  hideDialog,
  refetchRole,
}) => {
  const { tryAddRole, tryEditRole } = useRoleApiActions();
  const decoded = useDecodedData();
  const { data, isLoading } = useFetchAllControl();
  console.log("data---->", data);
  const [controls, setControls] = useState(initialValues.controls);
  const [arrays, setArrays] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  useEffect(() => {
    if (data) {
      const extractedArrays = Object.keys(data).reduce((result, key) => {
        if (Array.isArray(data[key])) {
          result[key] = data[key];
        }
        return result;
      }, {});
      setArrays(extractedArrays);

      const initialCheckboxState = Object.keys(extractedArrays).reduce(
        (result, key) => {
          result[key] = {
            parentChecked: false,
            childrenChecked: extractedArrays[key].map(() => false),
          };
          return result;
        },
        {}
      );
      setCheckboxState(initialCheckboxState);
    }
  }, [data]);

  // const handleChildChange = (arrayName, index, screenId, id) => {
  //   console.log(`Selected screenId: ${screenId}, id: ${id}`);
  //   setCheckboxState((prevState) => {
  //     const newChildrenChecked = [...prevState[arrayName].childrenChecked];
  //     newChildrenChecked[index] = !newChildrenChecked[index];

  //     const allChecked = newChildrenChecked.every((checked) => checked);

  //     return {
  //       ...prevState,
  //       [arrayName]: {
  //         parentChecked: allChecked,
  //         childrenChecked: newChildrenChecked,
  //       },
  //     };
  //   });
  // };

  const handleChildChange = (arrayName, index, screenId, controlId) => {
    console.log(`Selected screenId: ${screenId}, id: ${controlId}`);
    setCheckboxState((prevState) => {
      const newChildrenChecked = [...prevState[arrayName].childrenChecked];
      newChildrenChecked[index] = !newChildrenChecked[index];

      const allChecked = newChildrenChecked.every((checked) => checked);

      // Update controls state
      setControls((prevControls) => {
        if (newChildrenChecked[index]) {
          // Add the selected control to the array
          return [...prevControls, { screenId, controlId }];
        } else {
          // Remove the deselected control from the array
          return prevControls.filter(
            (control) =>
              control.screenId !== screenId || control.controlId !== controlId
          );
        }
      });

      return {
        ...prevState,
        [arrayName]: {
          parentChecked: allChecked,
          childrenChecked: newChildrenChecked,
        },
      };
    });
  };

  // useEffect(() => {
  //   if (data) {
  //     // Dynamically extract all arrays from the fetched data
  //     const extractedArrays = Object.keys(data).reduce((result, key) => {
  //       if (Array.isArray(data[key])) {
  //         result[key] = data[key];
  //       }
  //       return result;
  //     }, {});

  //     setArrays(extractedArrays);
  //   }
  // }, [data]);
  console.log("Arrays--------->", arrays);

  useEffect(() => {
    Object.keys(arrays).forEach((arrayName) => {
      console.log(`Array Name: ${arrayName}`);
      arrays[arrayName].forEach((item, idx) => {
        console.log(
          `Object ${idx + 1} keys in ${arrayName}:`,
          Object.keys(item)
        );
      });
    });
  }, [arrays]);

  const onSubmit = async (values, actions) => {
    const finalValues = {
      ...values,
      controls,
      status: values.status ? "active" : "disabled",
    };
    console.log("onSubmit", finalValues);
    if (user) {
      await tryEditRole(finalValues, user.id);
    } else {
      await tryAddRole(finalValues);
    }
    actions.resetForm();
    hideDialog();
    refetchRole();
    actions.resetForm();
  };

  useEffect(() => {
    if (user) {
      setFieldValue("name", user.name || "");
      setFieldValue("description", user.description || "");
    }
  }, [user]);

  const formik = useRoleForm(onSubmit, initialValues);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    setFieldValue,
  } = formik;

  console.log("errors---->", errors);
  // const keys = Object.keys(data);

  return (
    <React.Fragment>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
          mt: -1.5,
        }}
      >
        {" "}
        <Typography>
          To add role please select permissions and provide a role name to
          create
        </Typography>
      </Div>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <JumboFormLabelTextField
          id="roleName"
          onBlur={handleBlur}
          onChange={handleChange("roleName")}
          value={values.roleName}
          name="name"
          label="Role Name"
          placeholder={"Enter role name"}
          style={{ width: "500px" }}
        />
      </Div>
      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          alignItems: "flex-start",
          mb: 2,
        }}
      >
        <FormControlLabel
          value="end"
          control={
            <Switch
              color="primary"
              checked={values.isFullAccess}
              onChange={() =>
                setFieldValue("isFullAccess", !values.isFullAccess)
              }
            />
          }
          label="Grant full access"
          labelPlacement="end"
        />

        <FormControlLabel
          value="end"
          control={
            <Switch
              color="primary"
              checked={values.status} // Controlled value from formik
              onChange={() => setFieldValue("status", !values.status)} // Toggle the value on change
            />
          }
          label="Active"
          labelPlacement="end"
        />
      </Div>

      {!values.isFullAccess && (
        <>
          <Div
            sx={{
              display: "flex",

              minWidth: 0,
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Permissions
            </Typography>
          </Div>

          {isLoading ? (
            <JumboCustomLoader isList={true} isCustom={false} />
          ) : (
            Object.keys(arrays).map((arrayName, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mb: 2,
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <JumboCheckbox
                    checked={checkboxState[arrayName]?.parentChecked || false}
                    onChange={() => {
                      const allChildrenChecked =
                        !checkboxState[arrayName]?.parentChecked;
                      setCheckboxState((prevState) => ({
                        ...prevState,
                        [arrayName]: {
                          parentChecked: allChildrenChecked,
                          childrenChecked: prevState[
                            arrayName
                          ].childrenChecked.map(() => allChildrenChecked),
                        },
                      }));
                    }}
                    sx={{ verticalAlign: "middle" }}
                  />
                  <Typography variant="h5" mt={1} sx={{ fontWeight: "bold" }}>
                    {arrays[arrayName].find(
                      (item) => item.controlCode === "yourControlCode"
                    )?.name || arrayName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)", // 4 items per row
                    gap: 2, // Optional: Add some space between grid items
                    pl: 1,
                    width: "100%",
                  }}
                >
                  {arrays[arrayName].map((item, subIdx) => (
                    <Box
                      key={subIdx}
                      sx={{ display: "flex", alignItems: "center", mr: 2 }}
                    >
                      <JumboCheckbox
                        checked={
                          checkboxState[arrayName]?.childrenChecked[subIdx] ||
                          false
                        }
                        onChange={() =>
                          handleChildChange(
                            arrayName,
                            subIdx,
                            item.screenId,
                            item.id
                          )
                        }
                        // onChange={() => handleChildChange(arrayName, subIdx)}
                        sx={{ verticalAlign: "middle" }}
                      />
                      <Typography variant="h6" mt={1}>
                        {item.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Divider sx={{ width: "100%", mt: 2 }} />
              </Box>
            ))
          )}
        </>
      )}

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          size="large"
          disableElevation
          sx={{
            mb: 1,
            mr: 1.5,
          }}
          // disabled={isSubmitting}
          onClick={() => hideDialog()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          disableElevation
          onClick={() => handleSubmit()}
          sx={{
            mb: 1,
          }}
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Div>
    </React.Fragment>
  );
};

export default RoleForm;
