import React, { useState, useEffect } from "react";
import { roles } from "./components/data";
import RoleItem from "./components/RoleItem";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import TablePagination from "@mui/material/TablePagination";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import AddRole from "./components/AddRole";
import RoleForm from "./components/RoleForm";
import JumboCustomLoader from "@jumbo/components/JumboLoader";
import { useFetchAllRoles } from "./query/useFetchAllRoles";
import { useRoleApiActions } from "./query/useRoleApiActions";

const Settings = () => {
  const [roles, setRoles] = useState([
    {
      id: 1,
      name: "Admin",
      fullAccess: true,
      status: "Active",
      description: "Administrator",
      createdAt: "23-03-2019",
    },
    {
      id: 2,
      name: "Super Admin",
      fullAccess: true,
      status: "Active",
      description: "Administrator",
      createdAt: "23-03-2019",
    },
    {
      id: 3,
      name: "Associates",
      fullAccess: false,
      status: "Deactive",
      description: "Associates",
      createdAt: "23-03-2019",
    },
    {
      id: 4,
      name: "Customer",
      fullAccess: false,
      status: "Deactive",
      description: "Customer",
      createdAt: "23-03-2019",
    },
  ]);
  const { showDialog, hideDialog } = useJumboDialog();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    data: rolesData,
    isLoading: isLoadingRole,
    refetch: refetchRole,
  } = useFetchAllRoles();

  // const {
  //   data: rolesData,
  //   isLoading: isLoadingRole,
  //   refetch: refetchRole,
  // } = useFetchAllRoles(page, rowsPerPage);

  const { tryDeleteRole } = useRoleApiActions();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTimeout(() => {
      //   refetch();
    }, 500);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTimeout(() => {
      //   refetch();
    }, 500);
  };

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    };

    fetchData();
  }, []);

  const handleClose = (event) => {
    setAnchorEl(false);
  };
  const handleCreateRole = (roleData) => {};

  const handleUpdateRole = (roleId, updatedData) => {
    const updatedRoles = roles.map((role) =>
      role.id === roleId ? { ...role, ...updatedData } : role
    );
    setRoles(updatedRoles);
  };

  const handleDeleteRole = (roleId) => {
    showDialog({
      variant: "confirm",
      title: "Are you sure about deleting this contact?",
      content: "You won't be able to recover this contact later",
      onYes: () => {
        tryDeleteRole?.(roleId);

        hideDialog();
      },
      onNo: hideDialog,
    });
  };

  const showAddLabelDialog = React.useCallback(() => {
    showDialog({
      title: <Typography variant="h1">Add New Role</Typography>,
      content: (
        <RoleForm handleCreateRole={handleCreateRole} hideDialog={hideDialog} />
      ),
      maxWidth: "lg", // or 'sm', 'lg', 'xl' depending on your needs
      fullWidth: true,
    });
  }, []);

  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={2}
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        marginBottom={3}
      >
        <Typography variant={"h2"} mb={3}>
          Roles
        </Typography>
        <Button
          startIcon={<AddIcon />}
          sx={{
            padding: "10px 16px 10px 16px",
            gap: "8px",
            backgroundColor: "#371B65",
            textTransform: "none",
            fontFamily: "Roboto",
            fontSize: { lg: "14px", md: "12px", xs: "10px" },
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "0.05em",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          }}
          onClick={showAddLabelDialog}
        >
          ADD ROLE
        </Button>
      </Stack>

      {isLoading ? (
        <JumboCustomLoader isList={true} isCustom={false} />
      ) : (
        rolesData?.map((user, index) => (
          <RoleItem
            user={user}
            key={index}
            // refetchRole={refetchRole}
            handleUpdateRole={handleUpdateRole}
            handleDeleteRole={handleDeleteRole}
          />
        ))
      )}

      <TablePagination
        component="div"
        count={30 || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[20, 30, 50]}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};

export default Settings;
