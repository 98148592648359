import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  uploadDocument,
  addVersions,
} from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";
import { useSnackbar } from "notistack";

const useDocumentApiAction = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tryUploadDocument = async (values) => {
    try {
      const response = await uploadDocument(values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.document]);

        enqueueSnackbar("File added successfully.", {
          variant: "success",
        });
        navigate("/dashboards/workspace");
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  const tryAddVersions = async (values) => {
    try {
      const response = await addVersions(values);
      if (response) {
        queryClient.invalidateQueries([QueryKeys.document]);
        enqueueSnackbar("Versions added successfully.", {
          variant: "success",
        });
      }
      return response;
    } catch (err) {
      const msg = err?.error?.message || "Something went wrong";
      enqueueSnackbar(msg, {
        variant: "error",
      });
      return false;
    }
  };

  return {
    tryUploadDocument,
    tryAddVersions,
  };
};

export { useDocumentApiAction };
