/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchFileById } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";

async function getFileById(id) {
  try {
    const response = await fetchFileById(id);

    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchFileById = (id, enabled = true) => {
  const cacheKey = [QueryKeys.document];
  return useQuery(
    cacheKey,
    () => {
      return getFileById(id);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchFileById };
