import client from "./client";
import API_URLS from "./endPoints";

async function addRole(value) {
  return client.post(API_URLS.ROLE, value);
}

async function editRole(value, id) {
  return client.put(`${API_URLS.ROLE}/${id}`, value);
}

async function addUser(values) {
  return client.post(API_URLS.ADD_USER, values);
}

async function editUser(id, values) {
  return client.put(`${API_URLS.UPDATE_USER_BY_ID}/${id}`, values);
}

async function fetchAllRoles() {
  return client.get(`${API_URLS.ALLROLE}`);
}

async function deleteRole(id) {
  return client.delete(`${API_URLS.ROLE}/${id}`);
}

async function addFolder(values) {
  return client.post(API_URLS.ADD_FOLDER, values);
}

async function updateFolder(id, values) {
  return client.post(`${API_URLS.UPDATE_FOLDER}`, values);
}

async function fetchFolderById(id) {
  return client.get(`${API_URLS.FOLDER}/${id}`);
}

async function uploadDocument(values) {
  return client.post(API_URLS.DOCUMENT, values);
}

async function fileCheckoutCheckIn(id) {
  return client.put(`${API_URLS.CHECKOUT_CHECKIN}/${id}`);
}

async function deleteFolder(id) {
  return client.delete(`${API_URLS.DELETE_FOLDER}/${id}`);
}

async function fetchAllFolder() {
  return client.get(`${API_URLS.FOLDERS}`);
}

async function fetchFileByFolderId(id, page, limit, query) {
  return client.get(
    `${API_URLS.FILE_BY_FOLDER_ID}/${id}?page=${page}&limit=${limit}&search=${query}`
  );
}

async function addVersions(values) {
  return client.post(API_URLS.ADD_VERSIONS, values);
}

async function deleteFiles(ids) {
  return client.put(`${API_URLS.DELETE_FILES}`, ids);
}

async function fetchTrashFileFolder(type, page, limit, query) {
  return client.get(
    `${API_URLS.TRASH_FILE_FOLDER}/${type}?page=${page}&limit=${limit}&search=${query}`
  );
}

async function fetchFileById(id) {
  return client.get(`${API_URLS.FILE_BY_ID}/${id}`);
}

async function fetchDownloadFileByDriveId(id) {
  return client.get(`${API_URLS.DOWNLOAD_BY_FILEDRIVEID}/${id}`);
}

async function moveFile(fileId, folderId) {
  return client.put(
    `${API_URLS.MOVE_FILE}/${fileId}?destinationFolderId=${folderId}`
  );
}

async function restoreFiles(ids, type) {
  return client.put(`${API_URLS.RESTORE_FILES}`, ids, type);
}

async function permanentDeleteFile(values) {
  console.log("values", values);
  return client.put(API_URLS.DELETE_PERMANENT_FILE, values);
}

async function fetchAllControl() {
  return client.get(`${API_URLS.CONTROL}`);
}

async function fetchAllUser() {
  return client.get(`${API_URLS.ADD_USER}`);
}

async function fetchUserById(id) {
  return client.get(`${API_URLS.ADD_USER}/${id}`);
}

async function deleteUser(id) {
  return client.delete(`${API_URLS.ADD_USER}/${id}`);
}

export {
  deleteUser,
  fetchUserById,
  fetchAllUser,
  fetchAllControl,
  permanentDeleteFile,
  restoreFiles,
  moveFile,
  fetchDownloadFileByDriveId,
  fetchFileById,
  fetchTrashFileFolder,
  deleteFiles,
  addVersions,
  fetchFileByFolderId,
  fetchAllFolder,
  deleteFolder,
  fileCheckoutCheckIn,
  updateFolder,
  uploadDocument,
  fetchFolderById,
  addFolder,
  editUser,
  addUser,
  addRole,
  fetchAllRoles,
  editRole,
  deleteRole,
};
