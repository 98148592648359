import React, { useState, useEffect, useMemo } from "react";
import { FormControlLabel, FormLabel, Radio, Switch } from "@mui/material";
import Div from "./../../../../../../@jumbo/shared/Div/Div";
import JumboFormLabelTextField from "./../../../../../../@jumbo/components/JumboLabelTextfield/JumboLabelTextField";
import Span from "./../../../../../../@jumbo/shared/Span/Span";
import useSectionFormItem from "../../hooks/useSectionFormItem";

const defaultValues = {
  indexes: [
    {
      name: "",
      type: "",
      options: [],
      required: true,
    },
  ],
};

function SectionFormItem({ field, index, fieldIndex }) {
  const [formValues, setFormValues] = useState({});

  let parsedOptions = [];
  try {
    parsedOptions = JSON.parse(
      field.options.replace(/(\w+:)|(\w+ :)/g, function (matched) {
        return '"' + matched.substring(0, matched.length - 1) + '":';
      })
    );
    console.log("Parsed options:", parsedOptions);
  } catch (e) {
    console.error("Failed to parse options:", e);
  }

  const menuItems = useMemo(
    () =>
      Array.isArray(parsedOptions)
        ? parsedOptions.map((option) => {
            return { id: option.value, name: option.value };
          })
        : [],
    [parsedOptions]
  );

  const onSubmit = async (values) => {
    console.log("Form submitted", values);
  };

  const formik = useSectionFormItem(onSubmit, defaultValues);
  const { handleChange } = formik;

  const fieldType = field.type.trim();
  let formItem = null;

  switch (fieldType) {
    case "DropDown":
      formItem = (
        <JumboFormLabelTextField
          fullWidth
          isSelect
          menuItems={menuItems}
          labelStyle={{
            fontFamily: "Inter",
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={field.name}
          placeholder=""
          type="text"
          onChange={handleChange(
            `indexGroup[0].indexes[${index}].options[${fieldIndex}]`
          )}
        />
      );
      break;
    case "TextField":
      formItem = (
        <JumboFormLabelTextField
          fullWidth
          labelStyle={{
            fontFamily: "Inter",
            color: "#000000",
            fontSize: { xs: "1.2rem", md: "16px" },
            fontWeight: 400,
          }}
          label={field.name}
          placeholder={`Enter ${field.name}`}
          type="text"
          onChange={handleChange(`indexGroup[0].indexes[${index}].name`)}
        />
      );
      break;
    case "Checkbox":
      formItem = (
        <FormControlLabel
          control={
            <Switch
              checked={formValues[field.name] || false}
              onChange={handleChange(`indexGroup[0].indexes[${index}].name`)}
            />
          }
          label={field.name}
        />
      );
      break;
    case "RadioButton":
      formItem = (
        <Div sx={{ display: "flex", flexDirection: "column" }}>
          <FormLabel
            sx={{
              color: "inherit",
              fontSize: {
                xs: "12px",
                md: "14px",
                xl: "15px",
                fontWeight: "500",
              },
              marginTop: "5px",
              marginBottom: "4px",
              position: "relative",
              zIndex: 9999,
              textTransform: "capitalize",
            }}
          >
            {field?.name}
          </FormLabel>
          <Span>
            {parsedOptions.length > 0 &&
              parsedOptions.map((option, idx) => (
                <FormControlLabel
                  key={idx}
                  value={option.value}
                  control={<Radio />}
                  label={option.value}
                  onChange={handleChange(
                    `indexGroup[0].indexes[${index}].options[${fieldIndex}]`
                  )}
                />
              ))}
          </Span>
        </Div>
      );
      break;
    default:
      formItem = null;
  }

  return formItem;
}

export default SectionFormItem;
