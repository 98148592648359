/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchFolderById } from "../../../../services/pages.services";
import { QueryKeys } from "../../../../utils/QueryKeys";

async function getFolderById(id) {
  try {
    const response = await fetchFolderById(id);

    if (response) {
      return response;
    }
    return {
      statusCode: 404,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchFolderById = (
  id,

  enabled = true
) => {
  const cacheKey = [QueryKeys.folder, id];
  return useQuery(
    cacheKey,
    () => {
      return getFolderById(id);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchFolderById };
