import React from "react";
import Stack from "@mui/material/Stack";
import { Card, Typography, TableCell, TableRow, Checkbox } from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { getAssetPath } from "../../../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../../../utils/constants/paths";
import FileIcon from "./../../../workspace/components/FileIcon/index";
import moment from "moment";

const Item = styled(Span)(({ theme }) => ({}));

const FilesListItem = ({ user, isSelected, handleCheckboxChange, type }) => {
  return (
    <Card sx={{ mb: 1 }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChange(user.id)}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "40%", md: "40%", xs: "60%" } }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            {type === "file" ? (
              <Item>
                <FileIcon type={user.fileType} />
              </Item>
            ) : (
              <Item>
                <img
                  src={getAssetPath(`${ASSET_IMAGES}/folderIcon.png`, "32x32")}
                  alt="pin"
                  width={32}
                />
              </Item>
            )}
            <Item
              sx={{
                flex: { xs: 1, md: "0 1 45%", lg: "0 1 50%" },
                ml: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontWeight: 400,
                  fontSize: { md: "12px", lg: "14px", xs: "10px" },
                }}
              >
                {user.fileName || user?.folderName}
              </Typography>
              {type === "file" && (
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: { lg: "12px", md: "10px", xs: "8px" },
                    fontWeight: 500,
                    lineHeight: "31px",
                    color: "#A5A5A5",
                  }}
                >
                  {user?.size}MB
                </Typography>
              )}
            </Item>
          </Stack>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "8px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {/* {user?.deletedAt || user?.deletedAt} */}
            {moment(user?.deletedAt).format("DD/MM/YYYY h:mm A")}
          </Typography>
        </TableCell>
        <TableCell
          align="Left"
          sx={{
            width: "20%",
            display: { lg: "table-cell", md: "table-cell", xs: "none" },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "12px", md: "10px", xs: "8px" },
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: " 0.025em",
              color: "#9CA3AF",
            }}
          >
            {user?.checkOutUser?.firstName || user?.checkOutUser?.lastName ? (
              <>
                {user?.checkOutUser?.firstName} {user?.checkOutUser?.lastName}
              </>
            ) : (
              user?.deletedBy
            )}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          sx={{ width: { lg: "30%", md: "30%", xs: "40%" } }}
        >
          <Item>
            {" "}
            <Typography
              sx={{
                fontSize: { lg: "12px", md: "10px", xs: "8px" },
                fontWeight: 500,
                lineHeight: "16px",
                letterSpacing: " 0.025em",
                color: "#9CA3AF",
              }}
            >
              {user?.user?.firstName || user?.user?.lastName ? (
                <>
                  {user?.user?.firstName} {user?.user?.lastName}
                </>
              ) : (
                user?.createdBy
              )}
            </Typography>
          </Item>
        </TableCell>
      </TableRow>
    </Card>
  );
};

export default FilesListItem;
